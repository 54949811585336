#privacy{
    padding-top: 175px;
    background: #748173;
    padding-bottom: 100px;
    h1{
        @include  fontTemplate(5rem, 5.5rem, #fff, bold, $play, left);
        margin-bottom: 30px;
    }
    h2{
        @include  fontTemplate(3rem, 3.5rem, #fff, bold, $play, left);
        margin-bottom: 30px;
        margin-top: 50px;
    }
    p{
        @include  fontTemplate(1.6rem, 1.3, #fff, $semi-bold, $mont, left);
        margin-bottom: 30px;
    }
    li{
        @include  fontTemplate(1.6rem, 1.3, #fff, $semi-bold, $mont, left);
        margin-bottom: 15px;
    }
}
