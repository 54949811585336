#page_gastronomy {
    .fixed_icons{
        @media (max-width: 1100px){
            display: none;
        }
    }
    .section_food-slider {
        display: flex;
        padding: 90px 0px 128px;

        &--container {
            width: 95%;
            max-width: 1280px;
            margin: 0 auto;
            overflow: hidden;
        }

        .foodSwiper {
            max-width: 100% !important;
            min-width: 100% !important;

            .swiper-slide {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 38px;

                .image {
                    width: 80%;
                    aspect-ratio: 2.3;
                    object-fit: cover;
                    object-position: center;
                    @media (max-width: 1100px) {
                        width: 100%;
                        aspect-ratio: 1.9;
                    }
                }

                .description {
                    @include fontTemplate(
                        1.6rem,
                        1.9,
                        #5a5a5a,
                        $regular,
                        $mont,
                        center
                    );
                    max-width: 60%;
                    @media (max-width: 1100px) {
                        max-width: 100%;
                    }
                }
            }

            .swiper-button {
                &-prev,
                &-next {
                    &::after,
                    &::before {
                        display: none;
                    }

                    width: 50px !important;
                    height: 50px !important;
                    aspect-ratio: 1 !important;
                    border-radius: 50%;
                    background-color: #464d4542;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0;
                    padding: 0 !important;
                    transition: all .3s ease-out;
                    ion-icon {
                        font-size: 30px;
                        color: white;
                    }
                    &:hover{
                        background: $green;
                    }
                }
            }
        }
    }
}
