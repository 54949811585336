.section_gallery {
    padding: 110px 0px 0px;
    padding-bottom: 70px;
    &--container {
        width: 80%;
        max-width: 1500px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 15px;
        margin-bottom: 70px;
        @media (max-width: 1100px) {
            grid-template-columns: repeat(1, 80vw);
            gap: 15px;
            width: 100%;
            overflow: scroll;
            justify-content: center;
        }
    }
    &--title{
        font-size: 4rem;
        line-height: 1.1;
        color: #222222;
        font-weight: 400;
        font-family: "Playfair Display";
        text-align: start;
        grid-column: 1/-1;
    }
    a {
        width: 100%;
        aspect-ratio: 1;
        overflow: hidden;
        position: relative;
        img {
            width: 100%;
            aspect-ratio: 1;
            object-fit: cover;
            transition: .3s ease-out all;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
        }
    }

    button {
        margin: 0 auto;
        padding: 20px 47px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        @include fontTemplate(1.6rem, 1.1, $green, $regular, $play, center);
        width: fit-content;
        background-color: #fff;
        border: 1px solid $green;
        text-decoration: none;
        transition: 0.4s;

        &:hover {
            background-color: $green;
            color: #fff;
        }
        &:focus {
            background-color: #464d45;
        }
    }
}
