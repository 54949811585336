// body {
//     background-image: url(../img/parallel/Contato.png);
//     background-size: 100%;
//     background-repeat: no-repeat;
// }

#page_contact {
    .image-wrap{
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
        img{
            transition: all .3s ease !important;
        }
    }
    .mapa{
        width: 100%;
        height: 100%;
    }
    .section_banner .title{
        font-size: 7rem;
        line-height: 8.5rem;
        max-width: inherit;
        strong{
            font-size: 11rem;
            line-height: 9rem;
        }
        @media (max-width: 1100px){
            color: var(--default-white, #FFF);
            /* title */
            font-family: Playfair Display;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px; /* 117.857% */
            max-width: 80%;
        }
        strong{
            color: var(--default-white, #FFF);
            font-family: Betterlett;
            font-size: 63px;
            font-style: normal;
            font-weight: 400;
            line-height: 60px; /* 95.238% */
        }
    }
    .section_forms {
        padding: 50px 0px 100px;
        display: flex;
        overflow: hidden;
        &--container {
            width: 80%;
            max-width: 1200px;
            margin: 0 auto;
            @media (max-width: 1100px) {
                width: 100%;
                padding-inline: 15px;
            }
        }

        .title {
            @include fontTemplate(4rem, 1.1, #222222, $regular, $play);
            max-width: 65%;
            @media (max-width: 1100px) {
                max-width: 100%;
                font-size: 2.8rem;
            }
        }

        .description {
            margin-top: 30px;
            @include fontTemplate(2.2rem, 1.5, #222222, $regular, $mont);
            max-width: 65%;
            @media (max-width: 1100px) {
                max-width: 100%;
                font-size: 1.6rem;
            }
        }

        .box_content {
            display: grid;
            grid-template-columns: 34% 1fr;
            gap: 100px;
            margin-top: 113px;
            @media (max-width: 1100px) {
                grid-template-columns: 1fr;
                margin-top: 60px;
            }
            &-image {
                position: relative;
                width: 100%;
                height: 100%;
                @media (max-width: 1100px) {
                    display: none;
                }
                .reveal {
                    position: absolute;
                    transform: translate(-48%);
                    width: 190%;
                    height: 87%;
                    // background-color: red;
                }
                .double {
                    display: grid;
                    aspect-ratio: 1.15;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    counter-increment: section;
                    height: 100% !important;
                    width: 100% !important;
                }
                .double__img {
                    grid-area: 1 / 1 / -1 / -1;
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                    position: relative;
                }
            }

            form {
                padding-top: 10px;
                display: flex;
                flex-direction: column;
                gap: 50px;
                @media (max-width: 1100px) {
                    gap: 21px;
                }
                .input-group {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    label {
                        @include fontTemplate(
                            1.5rem,
                            1,
                            #748173,
                            $regular,
                            $mont
                        );
                    }
                    input,
                    textarea {
                        width: 100%;
                        padding: 24px 19px;
                        border: 1px solid #748173;
                        background-color: transparent;
                        @include fontTemplate(
                            1.5rem,
                            1,
                            #748173,
                            $regular,
                            $mont
                        );
                    }
                    .error--message {
                        display: none;
                    }
                    &.show--error {
                        input {
                            color: red;
                            border: 1px solid red;

                            &::placeholder {
                                color: red;
                            }
                        }
                    }

                    textarea {
                        min-height: 239px;
                    }
                }
                .buttons-group {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .label {
                        @include fontTemplate(
                            1.5rem,
                            1,
                            #748173,
                            $regular,
                            $mont
                        );
                    }
                    .box_button {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        gap: 30px;
                        @media (max-width: 1100px) {
                            grid-template-columns: 1fr;
                        }
                        button {
                            width: 100%;
                            padding: 24px 19px;
                            border: 1px solid #748173;
                            background-color: transparent;
                            @include fontTemplate(
                                1.5rem,
                                1,
                                #748173,
                                $regular,
                                $mont
                            );
                        }
                    }
                }
                .info-group {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 30px;
                    @media (max-width: 1100px) {
                        grid-template-columns: 1fr;
                    }
                }
                .terms-group {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 15px;
                    align-items: center;
                    margin-top: 17px;

                    .checkbox {
                        background: transparent;
                        display: block;
                        width: 17px;
                        height: 17px;
                        border: solid 2px #748173;
                        position: relative;
                        cursor: pointer;
                        > input {
                            opacity: 0;
                            position: absolute;
                            z-index: 10;
                            cursor: pointer;
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }

                        .check {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            margin: -3px;
                            clip-path: polygon(
                                0 0,
                                50% 0,
                                100% 0,
                                100% 100%,
                                50% 100%,
                                0 100%
                            );

                            transition: 0.4s cubic-bezier(0.8, 0, 0.2, 1);
                            transition-property: background-color, clip-path,
                                border-color;
                            transition-delay: 0.1s, 0s;
                        }

                        input:checked ~ .check {
                            background-color: #748173;
                            border-color: transparent;
                            transition-delay: 0s;

                            clip-path: polygon(
                                28% 38%,
                                41% 53%,
                                75% 24%,
                                86% 38%,
                                40% 78%,
                                15% 50%
                            );
                        }
                    }

                    label {
                        @include fontTemplate(
                            1.5rem,
                            1.5,
                            #748173,
                            $light,
                            $mont
                        );
                        a {
                            text-decoration: underline;
                        }
                    }

                    .error--message {
                        display: none;
                    }

                    &.show--error {
                        label {
                            color: red;
                        }
                    }
                }
                button[type="submit"] {
                    padding: 20px 48px;
                    border-radius: 100px;
                    width: fit-content;
                    transition: 0.4s;
                    background: #748173;
                    border: 1px solid #748173;
                    @include fontTemplate(1.6rem, 1, #fff, $regular, $play);
                    transition: all 0.3s ease-out;
                    &:hover{
                        background-color: #fff;
                        color: #748173;
                    }
                    &:focus {
                        background-color: #464d45;
                    }
                }
            }
        }
    }
    .section_location {
        display: flex;
        overflow: hidden;
        width: 100%;
        @media (max-width: 1100px) {
            padding-bottom: 0;
        }
        &--container {
            width: 100%;
            max-width: 1920px;
            display: grid;
            grid-template-columns: 1fr 55%;
            margin: 0 auto;
            align-items: start;
            @media (max-width: 1100px) {
                display: flex;
                flex-direction: column-reverse;
            }
            .title{
                display: none;
                @media (max-width: 1100px) {
                    display: block;
                    text-align: center;
                    margin-bottom: 30px;
                    color: #464d45;
                    /* title */
                    font-family: 'Playfair Display';
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 33px; /* 117.857% */
                    order: 1;
                }
            }
        }

        // Map
        .box_maps {
            width: 110%;
            position: relative;
            z-index: 0;
            background-color: red;
            aspect-ratio: 1.4;
            @media (max-width: 1100px) {
                order: 0;
            }
            img{
                width: 100%;
                height: 100%;
            }
        }

        // Descriptions
        .box_content {
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            z-index: 2;
            @media (max-width: 1100px) {
                order: 3;
            }
        }
        .group-contact {
            display: flex;
            flex-direction: column;
            gap: 27px;
            width: 100%;
            background-color: #748173;
            padding: 90px 18% 87px;
            @media (max-width: 1100px) {
                align-items: center;
            }
            &-title {
                @include fontTemplate(
                    5.3rem,
                    1.1,
                    #fff,
                    $regular,
                    $play,
                    center
                );
                width: fit-content;
                strong {
                    font-family: $better;
                    line-height: 1.2;
                    font-size: 7.3rem;
                    font-weight: 500;
                }
            }

            &-list {
                display: flex;
                flex-direction: column;
                gap: 30px;

                li {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 10px;
                    align-items: center;
                    @media (max-width: 1100px) {
                        grid-template-columns: 1fr;
                        justify-items: center;
                    }
                    ion-icon {
                        font-size: 2.7rem;
                        color: white;
                    }

                    p {
                        @include fontTemplate(
                            1.6rem,
                            1.9,
                            #fff,
                            $regular,
                            $mont
                        );
                        @media (max-width: 1100px) {
                            text-align: center;
                        }
                    }
                }
            }
        }
        .group-service {
            display: flex;
            flex-direction: column;
            gap: 27px;
            width: 100%;
            padding: 81px 17% 40px;
            @media (max-width: 1100px) {
                align-items: center;
            }
            &-title {
                @include fontTemplate(
                    5.3rem,
                    1.1,
                    #222,
                    $regular,
                    $play,
                    center
                );
                width: fit-content;
                strong {
                    font-family: $better;
                    line-height: 1.2;
                    font-size: 7.3rem;
                    font-weight: 500;
                    color: #748173;
                }
                @media (max-width: 1100px) {
                    text-align: center;
                    font-size: 3.5rem;
                    strong{
                        font-size: 7rem;
                    }
                }
            }

            &-list {
                display: flex;
                flex-direction: column;
                gap: 30px;

                li {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    gap: 10px;
                    align-items: center;
                    @media (max-width: 1100px) {
                        grid-template-columns: 1fr;
                        justify-items: center;
                    }
                    ion-icon {
                        font-size: 2.7rem;
                        color: #748173;
                    }

                    p {
                        @include fontTemplate(
                            1.6rem,
                            1.9,
                            #748173,
                            $regular,
                            $mont
                        );
                        @media (max-width: 1100px) {
                            text-align: center;
                        }
                    }
                }
            }

            &-description {
                @include fontTemplate(1.6rem, 1.9, #748173, $regular, $mont);
                @media (max-width: 1100px) {
                    text-align: center;
                }
            }
        }
    }
    .section_valuate {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 158px 0px 166px;
        display: flex;
        overflow: hidden;

        &--container {
            width: 80%;
            max-width: 1200px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 17%;
        }

        .box_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            &-title {
                @include fontTemplate(
                    4rem,
                    1.1,
                    #fff,
                    $regular,
                    $play,
                    center
                );
                width: fit-content;
                strong {
                    font-family: $better;
                    line-height: 1.2;
                    font-size: 6.2rem;
                    font-weight: 500;
                }
            }
            &-icons{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;

                a{
                    background-color: white;
                    width: 55px;
                    height: 55px;
                    aspect-ratio: 1;
                    border-radius: 50%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s ease-out;
                    &:hover{
                        background-color: #748173;
                        ion-icon{
                            color: #fff;
                        }
                    }
                }

                ion-icon{
                    font-size: 3rem;
                    color: #748173;
                    transition: all .3s ease-out;

                }
            }
        }
        .diviser {
            height: 140%;
            transform: translateY(-15%);
            width: 2px;
            background-color: white;
        }
    }
}
