#page_house{
    .container{
        max-width: 1196px;
    }
    .section_banner--container{
        .description{
            display: none;
        }
        .title{
            color: var(--secondary, #F8F7F7);
            font-family: Playfair Display;
            font-size: 5.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 5.6rem; /* 105.66% */
            strong{
                color: var(--secondary, #F8F7F7);
                font-family: Betterlett;
                font-size: 9rem;
                font-style: normal;
                font-weight: 400;
                line-height: 8.3rem; /* 92.222% */
            }
            @media (max-width: 500px){
                max-width: 80%;
            }
        }
    }
    .section_food-slider {
        display: flex;
        padding: 90px 0px 90px;
        @media (max-width: 1100px){
            padding: 90px 0px 0px;
        }
        .title{
            color: var(--default-black, #222);
            font-family: 'Playfair Display';
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 136.364% */
            margin-bottom: 30px;
            padding-left: 22px;
            @media (max-width: 1100px){
                padding-left: 3px;
            }
        }
        &--container {
            width: 95%;
            max-width: 1280px;
            margin: 0 auto;
            overflow: hidden;
        }

        .foodSwiper {
            max-width: 100% !important;
            min-width: 100% !important;
            .swiper-button-prev{
                @media (max-width: 1100px){
                    left: 0;
                }
            }
            .swiper-wrapper{
                padding-bottom: 4.5rem;
                @media (max-width: 1100px){
                    padding-bottom: 0;
                }
            }
            .swiper-slide {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 38px;
                transform: scale(1.2);

                .image {
                    width: 80%;
                    aspect-ratio: 2.3;
                    object-fit: cover;
                    object-position: center;
                }

                .description {
                    @include fontTemplate(
                        1.6rem,
                        1.9,
                        #5a5a5a,
                        $regular,
                        $mont,
                        center
                    );
                    max-width: 60%;
                }
            }

            .swiper-button {
                &-prev,
                &-next {
                    &::after,
                    &::before {
                        display: none;
                    }
                    width: 50px !important;
                    height: 50px !important;
                    aspect-ratio: 1 !important;
                    border-radius: 50%;
                    background-color: #464d4542;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 0;
                    padding: 0 !important;
                    transition: all .3s ease-out;
                    @media (max-width: 1100px){
                        top: 55px;
                    }
                    &:hover{
                        background: $green;
                    }
                    ion-icon {
                        font-size: 30px;
                        color: white;
                    }
                }
                &-next{
                    right: 0px;

                }
                &-left{
                    left: 0px;
                }
            }
        }
    }
    .cards{
        margin-top: 30px;
        @media (max-width: 1100px) {
            width: 100%;
            padding: 20px 0px;
        }
    }
    .card__house{
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 4px 20px 0px rgba(90, 90, 90, 0.10);
        border-bottom: 6px solid $green;
        height: auto;
        flex-direction: column;
        gap: 1.6rem;
        padding-bottom: 1rem;
        padding-inline: 1rem;
        @media (max-width: 1100px) {
            border-bottom: none;
        }
        .title{
            @include  fontTemplate(2.2rem, 1.1, $dark, $regular, $play, center);
        }
        .text{
            @include  fontTemplate(1.4rem, 16px, $grey, $regular, $mont, center);
            max-width: 218px;
        }
    }
    .motiva{
        margin-top: 50px;
        @media (max-width: 1100px){
            margin-top: 20px;
        }
        .title{
            @include  fontTemplate(4rem, 45px, $dark, $regular, $play, left);
            strong{
                font-family: $better;
                color: $green;
                font-size: 7.3rem;
                font-weight: 400;
            }
        }
        .text{
            @include  fontTemplate(1.6rem, 30px, $grey, $regular, $mont, left);
            margin-top: 60px;
        }
        .content-image{
            aspect-ratio: 0.87;
            img{
                transition: .3s ease-out all;
                height: 102%;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
        }

    }
    .structure{
        margin-top: 10rem;
        height: 434px;
        position: relative;
        padding-top: 10.6rem;
        @media (max-width: 1100px){
            margin-top: 8.4rem;
            padding-top: 8.4rem;
        }
        &::after{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            mix-blend-mode: multiply;
            background: $dark;
            opacity: .5;
        }
        .bg-img{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -2;
        }
        .title{
            @include  fontTemplate(5.3rem, 56px, #fff, $regular, $play, left);
            strong{
                font-family: $better;
                font-size: 7.3rem;
                line-height: 8.3rem;
                font-weight: 400;
            }
        }
        .text{
            @include  fontTemplate(1.6rem, 30px, #fff, $regular, $mont, left);
        }
        &__cards{
            position: relative;
            top: -85px;
            @media (max-width: 1100px){
                top: -20px;
            }
            .title{
                color: var(--default-black, #222);
                font-family: Playfair Display;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 136.364% */
                margin-top: 16px;
                margin-bottom: 16px;
                @media (max-width: 1100px){
                    text-align: center;
                }
            }
            .text{
                color: var(--bodyText, #5A5A5A);
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px; /* 187.5% */
                @media (max-width: 1100px){
                    text-align: center;
                }
            }
            .link-structure{
                color: var(--foundation-primary-normal, #748173);
                text-align: center;
                font-family: Playfair Display;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 55px; /* 343.75% */
                letter-spacing: -0.64px;
                display: flex;
                padding: 0px 50px;
                justify-content: center;
                align-items: center;
                border-radius: 34px;
                border: 1px solid var(--foundation-primary-normal, #748173);
                text-decoration: none;
                margin-top: 32px;
                transition:  all .3s ease;
                &:hover{
                    color: #fff;
                    background: $green;
                    border-color: $green;
                }
            }
            .card-structure{
                margin-bottom: 30px;
                display: flex;
                max-width: 590px;
                height: 352px;
                padding: 30px;
                flex-direction: column;
                align-items: flex-start;
                flex-shrink: 0;
                @media (max-width: 1100px) {
                    align-items: center;
                    min-height: 352px;
                    height: auto;
                }
                .icon{
                    margin-top: 38px;
                    width: 40px;
                    height: 40px;
                }
            }
            .card-white{
                border-bottom: 6px solid $green;
                background: #fff;
            }
            .card-brown{
                background: $green;
                .title{
                    color: #fff;
                }
                .text{
                    color: #fff;
                }
                .link-structure{
                    background: #fff;
                    border-color: #fff;
                    color: $green;
                    &:hover{
                        color: #fff;
                        background: $green;
                        border-color: #fff;
                    }
                }
            }
            .card-brow-btn, .card-white-btn{
                .icon{
                    margin-top: 13.5px;
                }
            }


        }
    }
    .story{
        margin-top: 12rem;
        padding-top: 9rem;
        position: relative;
        @media (max-width: 1100px) {
            padding-top: 6rem;
            margin-top: 0;
        }
        &::after{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 60%;
            height: 292px;
            background: $green;
            @media (max-width: 1600px) {
                height: 230px;
            }
            @media (max-width: 1100px) {
                width: 100%;
                height: 220px;
            }
        }
        .img-1{
            position: absolute;
            right: 0;
            top: 103px;
            aspect-ratio: 1.2;
            width: 50%;
            overflow: hidden;
            max-height: 600px;
            img{
                transition: all .3s ease-out;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            @media (max-width: 1100px) {
                display: none;
            }
        }
        .img-2{
            position: absolute;
            left: 0;
            bottom: 0;
            aspect-ratio: 1.2;
            width: 45%;
            overflow: hidden;
            max-height: 600px;
            img{
                transition: all .3s ease-out;
            }
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            @media (max-width: 1100px) {
                display: none;
            }
        }
        .mobile-img-1, .mobile-img-2{
            display: none;
            @media (max-width: 1100px) {
                display: block;
                width: 100%;
                max-height: 493px;
                object-fit: cover;
            }
        }
        .title{
            @include  fontTemplate(5.3rem, 56px, #fff, $regular, $play, left);
            position: relative;
            z-index: 9;
            strong{
                font-family: $better;
                font-size: 7.3rem;
                line-height: 8.3rem;
                font-weight: 400;
            }
        }
        .text{
            margin-top: 80px;
            @include  fontTemplate(1.6rem, 30px, $grey, $regular, $mont, left);
            @media (max-width: 1300px) {
                margin-top: 50px;
            }
            @media (max-width: 1100px) {
                margin-top: 7rem;
            }
        }
    }
}
