.form-group{
	position: relative;
	margin: 0;
}

input.error, textarea.error, select.error{
	background-color: #fce4e4;
	border: 1px solid #cc0033;
	outline: none;
}

label.error{
	color: red;
	font-size: 12px;
	position: absolute;
	bottom: -20px;
}
.container{
    @media (max-width: 1100px){
        padding-inline: 15px;
    }
}
.grecaptcha-badge {
    display:none !important;
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width:1280px;
    }
}
