.section_introduction {
    padding: 100px 0 0 0;
    position: relative;
    z-index: 2;
    @media (max-width: 1100px) {
        padding: 20px 0 0 0;
    }
    .image-wrap{
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
        img{
            transition: all 0.3s ease-in-out !important;
        }
    }
    &--container {
        width: 80%;
        max-width: 1196px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 34%;
        gap: 100px;
        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            width: 90%;
            gap: 30px;
        }
    }

    .box_text {
        margin-top: 20px;
        &-title {
            @include fontTemplate(4rem, 1.1, #222222, $regular, $play);
            max-width: 480px;

            strong {
                font-family: $better;
                color: #748173;
                line-height: 0;
                font-size: 7rem;
                font-weight: 500;
            }
            @media (max-width: 1100px) {
                color: var(--default-black, #222);
                /* title */
                font-family: Playfair Display;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px; /* 117.857% */
                strong{
                    color: var(--foundation-primary-normal, #748173);
                    font-family: Betterlett;
                    font-size: 45px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 40px; /* 95.238% */
                }
            }
        }

        &-description {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin-top: 63px;
            @media (max-width: 1100px) {
                margin-top: 30px;
            }
            @include fontTemplate(1.6rem, 1.9, #222222, $regular, $mont);
        }

        &-link {
            padding: 20px 47px;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            @include fontTemplate(
                1.6rem,
                1.1,
                #fff,
                $regular,
                $play,
                center
            );
            margin-top: 25px;
            width: fit-content;
            background-color: #748173,;
            text-decoration: none;
            transition: 0.4s;

            &:hover{
                background-color: #fff;
                color: $green;
                outline: 1px solid $green;
                svg{
                    path{
                        fill: $green;
                    }
                }
            }
            &:focus{
                background-color: #464D45;
            }
        }
    }

    .box_image{
        position: relative;
        width: 140%;
        aspect-ratio: 0.868;
        @media (max-width: 1100px){
            width: 100%;
            display: none;
        }
        .double {
            display: grid;
            aspect-ratio: 1.15;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            counter-increment: section;
            height: 100% !important;
            width: 100% !important;
        }

        .double__img {
            grid-area: 1 / 1 / -1 / -1;
            background-size: cover;
            width: 100%;
            height: 100%;
            position: relative;
        }
    }

    &-description{
        width: 50%;
        padding: 100px 7%;
        background-color: #748173;
        @include fontTemplate(4rem, 1.1, #fff, $regular, $play, center);
        margin-top: 75px;
        transform: translateY(35px);
        @media (max-width: 1100px){
            width: 100%;
            padding: 60px 4%;
            transform: translateY(0);
            text-align: start;
            margin-top: 40px;
        }
        strong {
            font-family: $better;
            line-height: 1.2;
            font-size: 7rem;
            font-weight: 500;
        }
    }
}
