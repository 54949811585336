html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

:root {
  --foundation-primary-normal: #748173;
  --default-black: #222;
  --bodyText: #5A5A5A;
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
@font-face {
  font-family: "Betterlett";
  src: url("../fonts/Betterlett-Regular.woff2") format("woff2"), url("../fonts/Betterlett-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
}
@media (max-width: 1499px) {
  html {
    font-size: 52.5%;
  }
}

.form-group {
  position: relative;
  margin: 0;
}

input.error, textarea.error, select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

@media (max-width: 1100px) {
  .container {
    padding-inline: 15px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1280px;
  }
}
#header {
  position: absolute;
  top: 0;
  height: 110px;
  z-index: 999;
  width: 100%;
}

.sub-links {
  display: none;
  position: absolute;
  width: 200%;
  top: 22px;
  padding: 12px 0;
  color: var(--default-white, #FFF);
  text-align: center;
  font-family: Montserrat;
  font-size: 15.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  border-bottom: solid 1px #ffffff;
  background: #748173;
  padding-left: 1.5rem;
}
.sub-links a {
  text-decoration: none;
  font-size: 1.55rem;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
}
.sub-links a:hover {
  font-weight: bold;
}
.sub-links ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.sub-links ul li {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: start;
}

.ambiente-link {
  cursor: pointer;
}

.sub-links.active {
  display: block;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 5rem;
  align-items: center;
}
@media (max-width: 1100px) {
  .wrapper {
    padding-inline: 9px;
  }
}
.wrapper .logo {
  max-width: 177px;
}
@media (max-width: 1300px) {
  .wrapper .logo {
    max-width: 150px;
  }
}
.wrapper .links {
  display: flex;
  gap: 3.4rem;
}
@media (max-width: 1300px) {
  .wrapper .links {
    gap: 2rem;
  }
}
@media (max-width: 1100px) {
  .wrapper .links {
    display: none;
  }
}
.wrapper .link {
  text-decoration: none;
  font-size: 1.55rem;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
  position: relative;
}
.wrapper .link span {
  color: #748173;
}
.wrapper .link::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0%;
  bottom: -4px;
  height: 2px;
  background: #748173;
  transition: all 0.2s ease;
}
.wrapper .link:hover::before {
  width: 100%;
}
.wrapper .link.active::before {
  width: 100%;
}
.wrapper .search {
  cursor: pointer;
}

#bg-menu-mobile {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: -100%;
  background: var(--default-black, #222);
  mix-blend-mode: multiply;
  will-change: transform;
}
#bg-menu-mobile ul li {
  font-weight: 300;
  font-size: 30px;
  position: relative;
  margin-bottom: 30px;
  left: 100%;
  cursor: pointer;
  will-change: transform;
}
#bg-menu-mobile ul li:hover {
  color: #111;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.sub-menu-mobile {
  padding-top: 3rem;
}

.menu-mobile-links {
  width: 100%;
  position: fixed;
  top: 100px;
  left: 0;
  list-style: none;
  color: #fff;
  z-index: 3;
  pointer-events: none;
  height: 80dvh;
  overflow-x: hidden;
  overflow-y: hidden;
}
.menu-mobile-links.active {
  pointer-events: auto;
  overflow-y: scroll;
}
.menu-mobile-links li {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 14px;
  left: 100%;
  cursor: pointer;
  will-change: transform;
  color: var(--default-white, #FFF);
  text-align: start;
  text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 166.667% */
  padding-left: 15px;
}
.menu-mobile-links li a {
  text-decoration: none;
}
.menu-mobile-links li::after {
  content: "";
  position: absolute;
  left: 15px;
  bottom: 0;
  width: 80%;
  height: 2px;
  background: #fff;
  opacity: 0.1;
}

.ambiete-mobile ul {
  display: none;
}
.ambiete-mobile .active {
  display: block;
}

/* hang burger */
#hamburger {
  width: 30px;
  height: 30px;
  position: relative;
  cursor: pointer;
  position: absolute;
  top: 45px;
  right: 15px;
  background-color: transparent;
  display: none;
}
@media (max-width: 1100px) {
  #hamburger {
    display: block;
  }
}

#hamburger span {
  z-index: 3;
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.25s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2) {
  top: 10px;
}

#hamburger span:nth-child(3) {
  top: 20px;
}

#hamburger.open .menu-mobile-links {
  pointer-events: all;
}

#hamburger.open span:nth-child(1) {
  top: 14px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  background-color: #fff;
}

#hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -30px;
  -webkit-transition: 0.16s ease-in-out;
  -moz-transition: 0.16s ease-in-out;
  -o-transition: 0.16s ease-in-out;
  transition: 0.16s ease-in-out;
}

#hamburger.open span:nth-child(3) {
  top: 14px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
  background-color: #fff;
}

#footer {
  padding-top: 112px;
  background: #F8F7F7;
}
@media (max-width: 1100px) {
  #footer {
    padding-top: 0;
  }
}
#footer .footer-bottom {
  border-top: 1px solid #748173;
  padding: 65px 0px;
  margin-top: 135px;
}
@media (max-width: 1100px) {
  #footer .footer-bottom {
    margin-top: 60px;
  }
}
#footer .footer-bottom--copyright {
  font-size: 1.55rem;
  line-height: 1;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
}
#footer .container-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
#footer .wrapper {
  display: grid;
  grid-template-columns: 225px repeat(4, 200px);
  justify-content: space-between;
  align-items: start;
}
@media (max-width: 1100px) {
  #footer .wrapper {
    padding-top: 42px;
    padding-inline: 9px;
    grid-template-columns: 1fr 1fr;
    row-gap: 60px;
  }
}
#footer .content {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1100px) {
  #footer .content:first-child {
    grid-column: span 2;
  }
}
#footer .content .logo {
  max-width: 165px;
  margin-bottom: 20px;
}
#footer .content h2 {
  margin-bottom: 4.4rem;
  font-size: 2rem;
  line-height: 1;
  color: #748173;
  font-weight: 500;
  font-family: "Playfair Display";
  text-align: left;
}
#footer .content a {
  font-size: 1.55rem;
  line-height: 1;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
  text-decoration: none;
  margin-bottom: 1.5rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1100px) {
  #footer .content a {
    font-size: 1.4rem;
  }
}
#footer .content a:hover {
  color: #748173;
}
#footer .content p {
  font-size: 1.75rem;
  line-height: 3.2rem;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
}
#footer .content .location {
  line-height: 3.2rem;
}

.termsfeed-com---nb-simple {
  right: 25vw;
  border: 2px solid #748173;
}
@media (max-width: 799px) {
  .termsfeed-com---nb-simple {
    right: 10vw;
  }
}

.termsfeed-com---nb .cc-nb-title {
  font-size: 2.4rem;
  line-height: 1;
  color: #222222;
  font-weight: bold;
  font-family: "Playfair Display";
  text-align: left;
}

.termsfeed-com---nb .cc-nb-text {
  font-size: 1.6rem;
  line-height: 1.2;
  color: #222222;
  font-weight: normal;
  font-family: "Montserrat";
  text-align: left;
}

.termsfeed-com---palette-light .cc-nb-okagree, .termsfeed-com---palette-light .cc-nb-reject {
  color: #748173;
  border: 1px solid #748173;
  border-radius: 32px;
  background: #fff;
  transition: all 0.3s ease;
}
.termsfeed-com---palette-light .cc-nb-okagree:hover, .termsfeed-com---palette-light .cc-nb-reject:hover {
  background: #748173;
  color: #fff;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: #fff;
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #748173;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: #748173;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #fff;
  font-size: 4.5rem;
  color: #748173;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #F8F7F7;
  font-weight: 400;
  font-family: center;
  text-align: start;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #F8F7F7;
  font-weight: 700;
  font-family: center;
  text-align: start;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  border: 1px solid #fff;
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #748173;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, #fff);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #748173;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: #748173;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #fff;
  font-size: 4.5rem;
  color: #748173;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: #F8F7F7;
  font-weight: 400;
  font-family: center;
  text-align: start;
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #F8F7F7;
  font-weight: 700;
  font-family: center;
  text-align: start;
  clip-path: polygon(100% 0, 100% 60%, 90% 100%, 0 100%, 0 0);
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.fail-message {
  padding: 10px 15px;
  background-color: #95201C;
  box-shadow: 0px 1px 5px black;
  position: fixed;
  right: 30px;
  bottom: 20px;
  color: white;
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 95%;
  align-items: center;
  display: none;
}

.success-message {
  padding: 10px 15px;
  background-color: #2C4325;
  box-shadow: 0px 1px 5px black;
  position: fixed;
  right: 30px;
  bottom: 20px;
  color: white;
  z-index: 100;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 95%;
  align-items: center;
  display: none;
}

.instagram .container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.instagram h2 {
  font-size: 4rem;
  line-height: 1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  text-align: center;
  line-height: 6.5rem;
  margin-bottom: 10rem;
}
.instagram h2 strong {
  font-family: "Betterlett";
  color: #748173;
  font-size: 7.3rem;
  font-weight: 100;
}

.blogNews {
  position: relative;
  padding-top: 7.5rem;
}
.blogNews .swiper {
  width: 100%;
}
.blogNews .swiper .swiper-button-prev, .blogNews .swiper .swiper-rtl .swiper-button-next {
  background: var(--foundation-primary-dark, #576156);
  width: 25px;
  height: 25px;
  left: 0;
}
.blogNews .swiper .swiper-button-prev::after, .blogNews .swiper .swiper-rtl .swiper-button-next::after {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.blogNews .swiper .swiper-button-next, .blogNews .swiper .swiper-rtl .swiper-button-prev {
  right: 0;
  background: var(--foundation-primary-dark, #576156);
  width: 25px;
  height: 25px;
}
.blogNews .swiper .swiper-button-next::after, .blogNews .swiper .swiper-rtl .swiper-button-prev::after {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.blogNews .date {
  display: none;
}
@media (max-width: 1100px) {
  .blogNews .date {
    display: block;
    color: var(--bodyText, #5A5A5A);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* 175% */
  }
}
.blogNews::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 460px;
  background: var(--foundation-primary-dark, #576156);
  z-index: -1;
}
.blogNews .container {
  display: flex;
  flex-direction: column;
  gap: 6.2rem;
}
.blogNews .texts {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
@media (max-width: 1100px) {
  .blogNews .texts {
    grid-template-columns: 1fr;
    row-gap: 42px;
  }
}
.blogNews .texts h2 {
  font-size: 4rem;
  line-height: 4.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
.blogNews .texts h2 strong {
  font-family: "Betterlett";
  font-weight: 100;
  font-size: 5.5rem;
}
.blogNews .texts p {
  font-size: 1.55rem;
  line-height: 3.5rem;
  color: #fff;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
}
.blogNews .cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.3rem;
  align-items: start;
}
@media (max-width: 1499px) {
  .blogNews .cards {
    gap: 40px !important;
  }
}
@media (max-width: 1100px) {
  .blogNews .cards {
    align-items: start;
    width: 100%;
    overflow: scroll;
    display: flex;
    gap: 1.6rem !important;
  }
}
.blogNews .card {
  border: none;
  border-radius: 0;
  min-width: inherit;
  background: transparent;
  transition: all 0.1s ease;
}
@media (max-width: 1100px) {
  .blogNews .card {
    min-width: 200px;
    width: 80%;
    margin: 0 auto;
  }
}
.blogNews .card:hover .card--img {
  transform: scale(1.2);
}
.blogNews .card .content-image {
  aspect-ratio: 0.85;
}
@media (max-width: 1499px) {
  .blogNews .card .content-image {
    aspect-ratio: 1 !important;
  }
}
.blogNews .card--img {
  width: 100%;
  height: 490px;
  transition: all 0.3s ease !important;
}
@media (max-width: 1100px) {
  .blogNews .card--img {
    height: 253px;
  }
}
.blogNews .card__content {
  padding: 2.2rem 2.5rem 2.9rem 3rem;
  width: calc(100% - 2.8rem);
  margin: 0 auto;
  background: #fff;
  position: relative;
  top: -60px;
  border-bottom: 6px solid #748173;
  display: flex;
  flex-direction: column;
}
.blogNews .card__content--title {
  font-size: 2.2rem;
  line-height: 1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
.blogNews .card__content--text {
  font-size: 1.55rem;
  line-height: 3rem;
  color: #748173;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
  margin-top: 2rem;
}
@media (max-width: 1100px) {
  .blogNews .card__content--text {
    display: none;
  }
}
.blogNews .card__content--cta {
  padding: 1.7rem 5rem;
  border-radius: 3.4rem;
  border: 1px solid #748173;
  font-size: 1.6rem;
  line-height: 1;
  color: #748173;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  width: fit-content;
  margin-top: 3rem;
  text-decoration: none;
  transition: all 0.3s ease;
  z-index: 9;
}
@media (max-width: 1100px) {
  .blogNews .card__content--cta {
    display: none;
  }
}
.blogNews .card__content--cta:hover {
  background: #748173;
  color: #fff;
}

.testimony {
  height: auto;
  position: relative;
  background: url("../img/depo/bg-depo.png") no-repeat;
}
.testimony::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #748173 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.8;
  z-index: 1;
  top: 0;
  left: 0;
}
.testimony .swiper .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background: #fff;
  opacity: 1;
  border: 1px solid #fff;
}
.testimony .swiper .swiper-pagination-bullet-active {
  background: transparent;
}
.testimony .swiper .swiper-horizontal > .swiper-pagination-bullets, .testimony .swiper .swiper-pagination-bullets.swiper-pagination-horizontal, .testimony .swiper .swiper-pagination-custom, .testimony .swiper .swiper-pagination-fraction {
  bottom: 0%;
}
.testimony .container {
  min-height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  padding: 50px 0;
}
.testimony .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}
.testimony .content .stars {
  height: 1.7rem;
}
.testimony .content .title {
  font-size: 3rem;
  line-height: 4rem;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
}
@media (max-width: 1100px) {
  .testimony .content .title {
    font-size: 2rem;
    line-height: 3rem;
  }
}
.testimony .content .name {
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  padding-bottom: 50px;
}

@media (max-width: 1100px) {
  #page_ambience .fixed_icons {
    display: none;
  }
}

.ambience {
  margin-bottom: 14.5rem;
  margin-top: 30rem;
}
@media (max-width: 1100px) {
  .ambience {
    margin-top: 60px;
    margin-bottom: 50px;
  }
}
.ambience .container {
  display: flex;
  gap: 10rem;
}
@media (max-width: 1100px) {
  .ambience .container {
    flex-direction: column;
    padding-inline: 15px;
    gap: 50px;
  }
}
.ambience .content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
  max-width: 620px;
}
.ambience .content .text {
  font-size: 1.55rem;
  line-height: 3rem;
  color: #5A5A5A;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
  margin-top: 1.6rem;
}
.ambience .card--main .title, .ambience .card--second .title, .ambience .card--grid .title {
  font-size: 2.2rem;
  line-height: 1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  margin-top: 1.1rem;
}
.ambience .card--main .content-image {
  aspect-ratio: 0.838;
  max-width: 550px;
}
@media (max-width: 1100px) {
  .ambience .card--main .content-image {
    aspect-ratio: 2.13;
  }
}
.ambience .card--main .content-image img {
  transition: all 0.3s ease-out !important;
}
.ambience .card--main .content-image:hover img {
  transform: scale(1.1);
}
.ambience .card--main .double {
  aspect-ratio: 0.838;
}
.ambience .card--main img {
  margin-top: 1.5rem;
  width: min(100%, 555px);
}
.ambience .card--second .content-image, .ambience .card--second .double {
  aspect-ratio: 2.13;
}
.ambience .card--second .content-image img, .ambience .card--second .double img {
  transition: all 0.3s ease-out !important;
}
.ambience .card--second .content-image:hover img, .ambience .card--second .double:hover img {
  transform: scale(1.1);
}
.ambience .card--grid .content-image, .ambience .card--grid .double {
  aspect-ratio: 1.6;
}
.ambience .card--grid .content-image img, .ambience .card--grid .double img {
  transition: all 0.3s ease-out !important;
}
.ambience .card--grid .content-image:hover img, .ambience .card--grid .double:hover img {
  transform: scale(1.1);
}
.ambience .card--grid img {
  width: 100%;
  aspect-ratio: 16/10;
  object-fit: cover;
}
.ambience .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 3rem;
  column-gap: 4rem;
}
.ambience .titleMain {
  font-size: 4rem;
  line-height: 4.5rem;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  max-width: 75%;
}
.ambience .titleMain strong {
  font-family: "Betterlett";
  color: #748173;
  font-size: 5.5rem;
  font-weight: 100;
}
.ambience .textMain {
  max-width: 75%;
}

.kitchen {
  padding-bottom: 10rem;
  padding-top: 11.7rem;
  position: relative;
  height: 690px;
}
@media (max-width: 1100px) {
  .kitchen {
    padding-top: 6rem;
    height: auto;
    padding-bottom: 6rem;
  }
}
.kitchen::after {
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  background: #748173;
  width: 65%;
  height: 100%;
  z-index: -1;
}
@media (max-width: 1100px) {
  .kitchen::after {
    width: 100%;
  }
}
.kitchen .container {
  display: flex;
  position: relative;
  max-width: 1280px !important;
}
.kitchen .content-image {
  aspect-ratio: 1.15;
  left: -180px;
  top: -45px;
  width: 841px;
}
@media (max-width: 1100px) {
  .kitchen .content-image {
    display: none;
  }
}
.kitchen .content-image img {
  transition: all 0.3s ease-out !important;
}
.kitchen .content-image:hover img {
  transform: scale(1.1);
}
.kitchen .content {
  width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  transform: translate(-80px, 0px);
}
@media (max-width: 1100px) {
  .kitchen .content {
    width: 100%;
    padding-inline: 15px;
    transform: translate(0px, 0px);
  }
}
.kitchen .title {
  font-size: 4rem;
  line-height: 4.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
.kitchen .title strong {
  font-family: "Betterlett";
  font-weight: 100;
  font-size: 7.5rem;
  line-height: 7.5rem;
}
.kitchen .text {
  font-size: 1.6rem;
  line-height: 3rem;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  margin-top: 3.5rem;
  max-width: 350px;
}
@media (max-width: 1500px) {
  .kitchen .text {
    max-width: 300px;
  }
}
.kitchen .cta {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  text-decoration: none;
  padding: 2.5rem 8.8rem;
  border-radius: 3.4rem;
  border: 1px solid #fff;
  margin-top: 5rem;
  width: fit-content;
  transition: all 0.3s ease;
}
.kitchen .cta:hover {
  background: #fff;
  color: #748173;
}

.space {
  margin-bottom: 16.9rem;
  margin-top: 5rem;
}
@media (max-width: 1100px) {
  .space {
    margin-bottom: 50px;
  }
}
.space .container {
  display: flex;
  justify-content: center;
  gap: 80px;
}
@media (max-width: 1100px) {
  .space .container {
    flex-direction: column;
    gap: 4rem;
    padding-inline: 15px;
  }
}
.space .content-image {
  width: 100%;
  height: 427px;
}
@media (max-width: 1100px) {
  .space .content-image {
    height: 300px;
  }
}
.space .content-image img {
  transition: all 0.3s ease-out !important;
}
.space .content-image:hover img {
  transform: scale(1.1);
}
.space .content {
  display: flex;
  flex-direction: column;
  max-width: 541px;
  height: 838px;
  justify-content: space-between;
}
@media (max-width: 1300px) {
  .space .content {
    max-width: 450px;
  }
}
@media (max-width: 1100px) {
  .space .content {
    height: auto;
    gap: 2rem;
  }
}
.space .content .title {
  font-size: 4rem;
  line-height: 4.5rem;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
.space .content .title strong {
  font-weight: 100;
  font-family: "Betterlett";
  color: #748173;
  font-size: 7.3rem;
  line-height: 7.3rem;
}
.space .content .text {
  font-size: 1.55rem;
  line-height: 3rem;
  color: #5A5A5A;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: left;
}
.space .content .cta {
  font-size: 1.6rem;
  line-height: 1;
  color: #748173;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  border-radius: 3.4rem;
  border: 1px solid #748173;
  padding: 2.5rem 5.8rem;
  width: fit-content;
  text-decoration: none;
  transition: all 0.3s ease;
}
.space .content .cta:hover {
  background: #748173;
  color: #fff;
}
.space .content--left .content-imag {
  order: 3;
}
.space .content--left .double {
  display: grid;
  aspect-ratio: 0.88;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  counter-increment: section;
}
.space .content--left .double__img {
  grid-area: 1/1/-1/-1;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.space .content--right .double {
  display: grid;
  aspect-ratio: 0.85;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  counter-increment: section;
}
.space .content--right .double__img {
  grid-area: 1/1/-1/-1;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.fixed_icons {
  position: absolute;
  top: 33%;
  right: 7%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 99;
}
@media (max-width: 1100px) {
  .fixed_icons {
    display: none;
  }
}
.fixed_icons a {
  width: 33px;
  height: 33px;
}

.fb-icon #path-1, .fb-icon #path-2, .fb-icon #path-3 {
  transition: all 0.3s ease-out;
}
.fb-icon:hover #path-1 {
  fill: #748173;
}
.fb-icon:hover #path-2 {
  fill: #fff;
}
.fb-icon:hover #path-3 {
  fill: #748173;
}

.insta-icon .path-1, .insta-icon .path-2 {
  transition: all 0.3s ease-out;
}
.insta-icon:hover .path-1 {
  fill: #748173;
}
.insta-icon:hover .path-2 {
  fill: #fff;
}

.linkedin-icon .path-1, .linkedin-icon .path-2 {
  transition: all 0.3s ease-out;
}
.linkedin-icon:hover .path-1 {
  fill: #748173;
}
.linkedin-icon:hover .path-2 {
  fill: #fff;
}

.wpp-icon .path-1, .wpp-icon .path-2 {
  transition: all 0.3s ease-out;
}
.wpp-icon:hover .path-1 {
  fill: #748173;
}
.wpp-icon:hover .path-2 {
  fill: #fff;
}

.wpp_fixed {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 5rem;
  right: 5rem;
  z-index: 99;
}

.section_banner {
  background-size: cover;
  background-repeat: no-repeat;
  height: auto;
  padding: 176px 0px 111px;
  display: flex;
  position: relative;
}
.section_banner:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  pointer-events: none;
}
.section_banner--container {
  width: 80%;
  max-width: 1196px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  .section_banner--container {
    width: 100%;
    padding-inline: 15px;
  }
}
.section_banner .navigate {
  display: flex;
  align-items: center;
  gap: 21px;
}
@media (max-width: 1100px) {
  .section_banner .navigate {
    gap: 10px;
  }
}
.section_banner .navigate a {
  font-size: 1.55rem;
  line-height: 1;
  color: #fff;
  font-weight: 500;
  font-family: "Montserrat";
  text-align: start;
  text-decoration: none;
}
@media (max-width: 1100px) {
  .section_banner .navigate a {
    line-height: 1.5;
  }
}
.section_banner .navigate a.active {
  text-underline-offset: 5px;
  text-decoration: underline;
}
.section_banner .title {
  font-size: 4rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
  max-width: 440px;
  margin-top: 68px;
  line-height: 4.5rem;
  /* 112.5% */
}
@media (max-width: 1100px) {
  .section_banner .title {
    line-height: 40px;
  }
}
.section_banner .title strong {
  font-family: "Betterlett";
  font-weight: 500;
  line-height: 1.4;
  font-size: 7rem;
}
.section_banner .description {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 60%;
  background: #748173;
  padding: 1.5rem;
  font-size: 1.6rem;
  line-height: 1.9;
  color: #fff;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
@media (max-width: 1100px) {
  .section_banner .description {
    max-width: 100%;
  }
}
.section_banner .group-button {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 1100px) {
  .section_banner .group-button {
    align-items: flex-start;
    gap: 25px;
    flex-direction: column;
  }
}
.section_banner .link, .section_banner .actions {
  padding: 20px 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 1.1;
  color: #748173;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  margin-top: 35px;
  width: fit-content;
  background-color: white;
  text-decoration: none;
  display: flex;
  gap: 10px;
  transition: 0.3s ease-out all;
}
.section_banner .link svg path, .section_banner .actions svg path {
  transition: 0.3s ease-out all;
}
.section_banner .link:hover, .section_banner .actions:hover {
  background-color: #748173;
  color: white;
}
.section_banner .link:hover svg path, .section_banner .actions:hover svg path {
  fill: white;
}
.section_banner .arrow-down {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: rgba(212, 216, 212, 0.2549019608);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}
.section_banner .arrow-down ion-icon {
  font-size: 30px;
  color: white;
}
@media (max-width: 1499px) {
  .section_banner {
    padding: 130px 0px 100px;
  }
}
@media (max-width: 1100px) {
  .section_banner {
    padding: 130px 0px 84px;
  }
}

.section_introduction {
  padding: 100px 0 0 0;
  position: relative;
  z-index: 2;
}
@media (max-width: 1100px) {
  .section_introduction {
    padding: 20px 0 0 0;
  }
}
.section_introduction .image-wrap:hover img {
  transform: scale(1.1);
}
.section_introduction .image-wrap img {
  transition: all 0.3s ease-in-out !important;
}
.section_introduction--container {
  width: 80%;
  max-width: 1196px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 34%;
  gap: 100px;
}
@media (max-width: 1100px) {
  .section_introduction--container {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: 30px;
  }
}
.section_introduction .box_text {
  margin-top: 20px;
}
.section_introduction .box_text-title {
  font-size: 4rem;
  line-height: 1.1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
  max-width: 480px;
}
.section_introduction .box_text-title strong {
  font-family: "Betterlett";
  color: #748173;
  line-height: 0;
  font-size: 7rem;
  font-weight: 500;
}
@media (max-width: 1100px) {
  .section_introduction .box_text-title {
    color: var(--default-black, #222);
    /* title */
    font-family: Playfair Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 117.857% */
  }
  .section_introduction .box_text-title strong {
    color: var(--foundation-primary-normal, #748173);
    font-family: Betterlett;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 95.238% */
  }
}
.section_introduction .box_text-description {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 63px;
  font-size: 1.6rem;
  line-height: 1.9;
  color: #222222;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
@media (max-width: 1100px) {
  .section_introduction .box_text-description {
    margin-top: 30px;
  }
}
.section_introduction .box_text-link {
  padding: 20px 47px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.6rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  margin-top: 25px;
  width: fit-content;
  background-color: #748173;
  text-decoration: none;
  transition: 0.4s;
}
.section_introduction .box_text-link:hover {
  background-color: #fff;
  color: #748173;
  outline: 1px solid #748173;
}
.section_introduction .box_text-link:hover svg path {
  fill: #748173;
}
.section_introduction .box_text-link:focus {
  background-color: #464D45;
}
.section_introduction .box_image {
  position: relative;
  width: 140%;
  aspect-ratio: 0.868;
}
@media (max-width: 1100px) {
  .section_introduction .box_image {
    width: 100%;
    display: none;
  }
}
.section_introduction .box_image .double {
  display: grid;
  aspect-ratio: 1.15;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  counter-increment: section;
  height: 100% !important;
  width: 100% !important;
}
.section_introduction .box_image .double__img {
  grid-area: 1/1/-1/-1;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
.section_introduction-description {
  width: 50%;
  padding: 100px 7%;
  background-color: #748173;
  font-size: 4rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  margin-top: 75px;
  transform: translateY(35px);
}
@media (max-width: 1100px) {
  .section_introduction-description {
    width: 100%;
    padding: 60px 4%;
    transform: translateY(0);
    text-align: start;
    margin-top: 40px;
  }
}
.section_introduction-description strong {
  font-family: "Betterlett";
  line-height: 1.2;
  font-size: 7rem;
  font-weight: 500;
}

.section_gallery {
  padding: 110px 0px 0px;
  padding-bottom: 70px;
}
.section_gallery--container {
  width: 80%;
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-bottom: 70px;
}
@media (max-width: 1100px) {
  .section_gallery--container {
    grid-template-columns: repeat(1, 80vw);
    gap: 15px;
    width: 100%;
    overflow: scroll;
    justify-content: center;
  }
}
.section_gallery--title {
  font-size: 4rem;
  line-height: 1.1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
  grid-column: 1/-1;
}
.section_gallery a {
  width: 100%;
  aspect-ratio: 1;
  overflow: hidden;
  position: relative;
}
.section_gallery a img {
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  transition: 0.3s ease-out all;
}
.section_gallery a:hover img {
  transform: scale(1.1);
}
.section_gallery button {
  margin: 0 auto;
  padding: 20px 47px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 1.6rem;
  line-height: 1.1;
  color: #748173;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  width: fit-content;
  background-color: #fff;
  border: 1px solid #748173;
  text-decoration: none;
  transition: 0.4s;
}
.section_gallery button:hover {
  background-color: #748173;
  color: #fff;
}
.section_gallery button:focus {
  background-color: #464d45;
}

#back-to-top {
  margin: 70px auto 100px;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  width: 70px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #748173;
  transition: 0.4s;
}
@media (max-width: 1100px) {
  #back-to-top {
    margin: 60px auto;
    transform: translateX(0);
    width: 55px;
    margin-top: 0;
  }
}
#back-to-top ion-icon {
  font-size: 40px;
  color: #748173;
  transition: 0.4s;
}
#back-to-top:hover {
  border: 1px solid #5D675C;
  background-color: #5D675C;
}
#back-to-top:hover ion-icon {
  color: white;
}
#back-to-top:focus {
  background-color: #464D45;
  border: 1px solid #464D45;
}
#back-to-top:focus ion-icon {
  color: white;
}

.section_cta {
  padding: 170px 0px;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}
.section_cta::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background: #222222;
  opacity: 0.5;
}
@media (max-width: 1100px) {
  .section_cta {
    padding: 90px 0px;
  }
}
.section_cta--container {
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto 1fr;
  width: 80%;
  max-width: 1297px;
  gap: 130px;
  align-items: center;
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  .section_cta--container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-inline: 15px;
    gap: 21px;
  }
}
.section_cta .title {
  font-size: 4rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
}
.section_cta .title strong {
  font-family: "Betterlett";
  font-weight: 500;
  line-height: 1.2;
  font-size: 7rem;
}
.section_cta .description {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 60%;
  font-size: 1.6rem;
  line-height: 1.9;
  color: #fff;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
.section_cta .link,
.section_cta .actions {
  padding: 20px 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  line-height: 1.1;
  color: #748173;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  margin-top: 35px;
  width: fit-content;
  background-color: white;
  text-decoration: none;
  transition: 0.3s all ease-out;
}
.section_cta .link:hover,
.section_cta .actions:hover {
  background-color: #748173;
  color: #F8F7F7;
}

.destaque {
  margin-top: 120px;
}
.destaque .swiper-button-prev, .destaque .swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  aspect-ratio: 1 !important;
  border-radius: 50%;
  background-color: rgba(70, 77, 69, 0.2588235294);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 !important;
  transition: all 0.3s ease-out;
}
.destaque .swiper-button-prev::after, .destaque .swiper-button-prev::before, .destaque .swiper-button-next::after, .destaque .swiper-button-next::before {
  display: none;
}
.destaque .swiper-button-prev ion-icon, .destaque .swiper-button-next ion-icon {
  font-size: 30px;
  color: white;
}
.destaque .swiper-button-prev:hover, .destaque .swiper-button-next:hover {
  background: #748173;
}
.destaque .swiper-container {
  position: relative;
  margin: 0 auto;
}
.destaque .swiper-container .swiper-button-prev {
  left: -73px;
}
@media (max-width: 1100px) {
  .destaque .swiper-container .swiper-button-prev {
    display: none;
  }
}
.destaque .swiper-container .swiper-button-next {
  right: -73px;
}
@media (max-width: 1100px) {
  .destaque .swiper-container .swiper-button-next {
    display: none;
  }
}
.destaque h2 {
  margin-bottom: 26px;
}
.destaque h2 strong {
  font-size: 4rem;
  line-height: 1;
  color: #748173;
  font-weight: 500;
  font-family: "Betterlett";
  text-align: center;
  letter-spacing: -1.6px;
}
.destaque .cardsExpirence {
  margin-top: 20px;
}
@media (max-width: 1100px) {
  .destaque .cardsExpirence {
    margin-top: 20px;
  }
}
@media (max-width: 1100px) {
  .destaque .cardsExpirence .box-cards {
    width: 100%;
  }
  .destaque .cardsExpirence .box-cards .row {
    flex-wrap: nowrap;
  }
}
.destaque .cardsExpirence .card-expirence {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.destaque .cardsExpirence .card-expirence .wrapper-img {
  width: 100%;
  height: 390px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  .destaque .cardsExpirence .card-expirence .wrapper-img {
    height: 364px;
  }
}
.destaque .cardsExpirence .card-expirence img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  object-fit: cover;
}
.destaque .cardsExpirence .card-expirence:hover img {
  transform: scale(1.1);
}
.destaque .cardsExpirence .card-expirence .body {
  background: #fff;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 30px);
  top: -122px;
  position: relative;
  background: var(--default-white, #FFF);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 6px solid #748173;
  gap: 11px;
}
@media (max-width: 1100px) {
  .destaque .cardsExpirence .card-expirence .body {
    padding: 30px 21px;
    margin-bottom: -62px;
  }
}
.destaque .cardsExpirence .card-expirence .body p {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  /* 187.5% */
}
.destaque .cardsExpirence .card-expirence .body .date {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 187.5% */
}

.newsletter {
  min-height: 367px;
  position: relative;
  display: flex;
  align-items: center;
}
.newsletter::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #748173;
  opacity: 0.8;
  mix-blend-mode: multiply;
}
.newsletter .bg-img {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}
.newsletter .title {
  color: #FFF;
  font-family: Playfair Display;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  /* 112.5% */
  position: relative;
  z-index: 9;
}
.newsletter .title strong {
  color: #FFF;
  font-family: Betterlett;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 7rem;
  /* 116.667% */
}
.newsletter .box-send {
  position: relative;
  z-index: 9;
}
@media (max-width: 1100px) {
  .newsletter .box-send {
    margin-top: 60px;
  }
}
.newsletter #newsletter-input {
  width: 100%;
  border: none;
  height: 70px;
  border-radius: 34px;
  opacity: 0.7;
  background: var(--secondary, #F8F7F7);
  padding-left: 50px;
  font-size: 1.6rem;
  color: var(--bodyText, #5A5A5A);
}
@media (max-width: 1100px) {
  .newsletter #newsletter-input {
    height: 50px;
  }
}
.newsletter #newsletter-input::placeholder {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 200% */
}
.newsletter .btn-send {
  position: absolute;
  right: 30px;
  top: 5px;
}
@media (max-width: 1100px) {
  .newsletter .btn-send {
    right: 0;
    top: -5px;
    transform: scale(0.8);
  }
}

#page404 .title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
}
#page404 .title .sad {
  transform: rotate(90deg);
  font-size: 60px;
}

#home .fixed_icons {
  display: flex;
}

.slider-home {
  height: 100vh;
  position: relative;
}
.slider-home .swiper {
  width: 100%;
  height: 100%;
}
.slider-home .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider-home .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-home .swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1500px) {
  .slider-home .swiper {
    height: 250px;
  }
}
@media (max-height: 650px) {
  .slider-home .swiper {
    height: 200px;
  }
}
.slider-home .mySwiper .swiper-wrapper {
  align-items: center;
}
.slider-home .swiper-slide {
  background-size: cover;
  background-position: center;
}
.slider-home .mySwiper2 {
  height: 100%;
  width: 100%;
  position: relative;
}
.slider-home .mySwiper2 .swiper-slide {
  position: relative;
}
.slider-home .mySwiper2 .swiper-slide::after {
  background: #585857 0 0 no-repeat padding-box;
  content: "";
  height: 100%;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.slider-home .mySwiper2 .destaque-text {
  position: absolute;
  width: 100%;
  height: 200px;
  top: -10%;
  left: 0;
  z-index: 99;
}
@media (max-width: 1100px) {
  .slider-home .mySwiper2 .destaque-text {
    top: 8%;
  }
}
.slider-home .mySwiper2 .destaque-text span {
  font-size: 345px;
  color: #fff;
  opacity: 0.1;
  font-family: "Playfair Display";
}
@media (max-width: 1100px) {
  .slider-home .mySwiper2 .destaque-text span {
    font-size: 100px;
  }
}
.slider-home .mySwiper2 .content {
  display: flex;
  position: absolute;
  right: 23%;
  top: 30%;
  flex-direction: column;
  z-index: 99;
  gap: 4.5rem;
}
@media (max-width: 1100px) {
  .slider-home .mySwiper2 .content {
    right: inherit;
    width: calc(100% - 30px);
    top: 33%;
  }
}
.slider-home .mySwiper2 .content .title {
  font-size: 5rem;
  line-height: 5.5rem;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  max-width: 500px;
}
.slider-home .mySwiper2 .content .title strong {
  color: #fff;
  font-family: "Betterlett";
  font-weight: 100;
  line-height: 7.5rem;
  font-size: 7.5rem;
}
@media (max-width: 1100px) {
  .slider-home .mySwiper2 .content .title strong {
    font-size: 4rem;
    line-height: 5rem;
  }
}
@media (max-width: 1100px) {
  .slider-home .mySwiper2 .content .title {
    font-size: 3.5rem;
    line-height: 4rem;
  }
}
.slider-home .mySwiper2 .content .cta {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  padding: 2.5rem 5rem;
  transition: all 0.3s ease;
  border-radius: 3.4rem;
  border: 1px solid #fff;
  width: fit-content;
  text-decoration: none;
}
.slider-home .mySwiper2 .content .cta:hover {
  background: #748173;
  color: #fff;
}
.slider-home .mySwiper2 .swiper-horizontal > .swiper-pagination-bullets, .slider-home .mySwiper2 .swiper-pagination-bullets.swiper-pagination-horizontal, .slider-home .mySwiper2 .swiper-pagination-custom, .slider-home .mySwiper2 .swiper-pagination-fraction {
  bottom: 15vh;
}
.slider-home .mySwiper2 .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: transparent;
  border: 1px solid #fff;
  opacity: 1;
}
.slider-home .mySwiper2 .swiper-pagination-bullet-active {
  background: #748173;
  border-color: #748173;
}
.slider-home .mySwiper {
  box-sizing: border-box;
  padding: 10px 0;
}
@media (max-width: 1100px) {
  .slider-home .mySwiper {
    left: 0;
    width: 100%;
    bottom: 0;
    padding-inline: 35px;
  }
}
.slider-home .mySwiper .swiper-button-prev, .slider-home .mySwiper .swiper-rtl .swiper-button-next {
  background: var(--foundation-primary-dark, #576156);
  width: 25px;
  height: 25px;
  left: 5px;
  top: 58%;
}
.slider-home .mySwiper .swiper-button-prev::after, .slider-home .mySwiper .swiper-rtl .swiper-button-next::after {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.slider-home .mySwiper .swiper-button-next, .slider-home .mySwiper .swiper-rtl .swiper-button-prev {
  background: var(--foundation-primary-dark, #576156);
  width: 25px;
  height: 25px;
  right: 5px;
  top: 58%;
}
.slider-home .mySwiper .swiper-button-next::after, .slider-home .mySwiper .swiper-rtl .swiper-button-prev::after {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.slider-home .mySwiper .wrapper-img-thumb {
  aspect-ratio: 1.83;
  overflow: hidden;
}
.slider-home .mySwiper .wrapper-img-thumb img {
  transition: all 0.3s ease-out;
}
.slider-home .mySwiper .wrapper-img-thumb:hover img {
  transform: scale(1.1);
}
.slider-home .mySwiper .center-text {
  display: flex;
  justify-content: center;
}
.slider-home .mySwiper .center-text::after, .slider-home .mySwiper .center-text::before {
  position: absolute;
  content: "";
  left: 0;
  top: 7%;
  height: 1px;
  width: 45%;
  background: #fff;
}
@media (max-width: 1100px) {
  .slider-home .mySwiper .center-text::after, .slider-home .mySwiper .center-text::before {
    width: 35%;
  }
}
.slider-home .mySwiper .center-text::before {
  right: 0;
  left: inherit;
}
.slider-home .mySwiper .center-text span {
  font-size: 2rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Playfair Display";
  text-align: left;
  position: relative;
}
.slider-home .mySwiper .swiper-slide {
  width: 25%;
  height: 80%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: flex-start;
  gap: 1.8rem;
  cursor: pointer;
}
.slider-home .mySwiper .swiper-slide p {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
}
.slider-home .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
.slider-home .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-home {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
  height: 100vh;
  /* Add styles for the thumbnail navigation */
}
.slider-home ::-webkit-scrollbar {
  width: 10px;
}
.slider-home ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.slider-home ::-webkit-scrollbar-thumb {
  background: #748173;
}
.slider-home ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.slider-home .scroll {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 0.5);
  font-family: "font-2";
  font-size: calc(0.5rem + 0.35vw);
  z-index: 10;
}
.slider-home .slider {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider-home .slider__text {
  position: absolute;
  bottom: calc(2rem + 26vw);
  right: calc(2rem + 15vw);
  z-index: 10;
  font-size: 53px;
  transform-origin: top;
  line-height: 56px;
  color: #748173;
  font-weight: 400;
}
.slider-home .break-line {
  font-size: 5rem;
  line-height: 5.5rem;
  color: #748173;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  position: absolute;
  top: -45vh;
  right: -25vw;
}
.slider-home .break-line strong {
  color: #fff;
  font-family: "Betterlett";
  font-weight: 100;
  line-height: 9.5rem;
  font-size: 7.5rem;
}
@media (max-width: 1599px) {
  .slider-home .break-line {
    font-size: 4rem;
    line-height: 3.5rem;
  }
  .slider-home .break-line strong {
    line-height: 6.5rem;
    font-size: 5.5rem;
  }
}
.slider-home .cta-position {
  right: -22vw;
  position: relative;
  top: -16vh;
}
@media (max-width: 1599) {
  .slider-home .cta-position {
    right: 25vw;
  }
}
.slider-home .line-father {
  overflow: hidden;
}
.slider-home .slider__text-line {
  overflow: hidden;
}
.slider-home .slider__text-line strong {
  font-size: 7rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 100;
  font-family: "Betterlett";
  text-align: left;
}
.slider-home .slider__inner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider-home .container-text {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  bottom: -20%;
}
.slider-home .slider__nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 10;
}
.slider-home .slider-bullet {
  display: flex;
  align-items: center;
  padding: 1rem 0;
  display: none;
}
.slider-home .slider-bullet__text {
  color: #fff;
  font-size: 0.65rem;
  margin-right: 1rem;
}
.slider-home .slider-bullet__line {
  background-color: #fff;
  height: 1px;
  width: 1rem;
}
.slider-home .slider canvas {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider-home .slide {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.slider-home .slide__content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.slider-home .slide__img {
  position: relative;
  width: 25vw;
  height: 70vh;
  padding: 0;
  margin: 0;
  min-width: 12.5rem;
  transform-origin: top;
}
.slider-home .slide__img:first-child {
  top: -1.5rem;
}
.slider-home .slide__img:last-child {
  bottom: -1.5rem;
}
.slider-home .slide__img img {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider-home .js-slider-thumbs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  position: absolute;
  bottom: -300px;
  left: 0;
  z-index: 99;
  gap: 20px;
  max-width: 1280px;
  overflow-y: hidden;
  overflow-x: hidden;
}
@media (max-width: 1599px) {
  .slider-home .js-slider-thumbs {
    max-width: 950px;
  }
}
.slider-home .js-slider-thumb {
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;
}
.slider-home .js-slider-thumb p {
  font-size: 1.6rem;
  line-height: 1.2;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  text-transform: capitalize;
  transform: translate(0px, 18px);
}
.slider-home .wrapper-img {
  width: 300px;
  height: 163px;
  overflow: hidden;
}
@media (max-width: 1599px) {
  .slider-home .wrapper-img {
    width: 250px;
  }
}
.slider-home .js-slider-thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.slider-home .js-slider-thumb:hover img {
  transform: scale(1.3);
}
.slider-home .container {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  bottom: 34%;
}
@media (max-width: 1399px) {
  .slider-home .container {
    max-width: 1140px;
    bottom: 34%;
  }
}
@media (max-width: 768px) {
  .slider-home .container {
    padding: 0;
  }
}
.slider-home .know {
  position: relative;
  bottom: -60px;
}
.slider-home .know::after, .slider-home .know::before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 45%;
  height: 1px;
  background: #fff;
}
.slider-home .know::before {
  right: 0;
  left: inherit;
}
.slider-home .know h4 {
  text-align: center;
  color: #fff;
}
.slider-home .cta-banner {
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  padding: 2.5rem 5rem;
  transition: all 0.3s ease;
  border-radius: 3.4rem;
  border: 1px solid #748173;
  width: -moz-fit-content;
  width: fit-content;
  text-decoration: none;
}
@media (max-width: 1599px) {
  .slider-home .cta-banner {
    padding: 2rem 3rem;
  }
}
.slider-home .cta-banner:hover {
  background: #748173;
  color: #fff;
}

#page_house .container {
  max-width: 1196px;
}
#page_house .section_banner--container .description {
  display: none;
}
#page_house .section_banner--container .title {
  color: var(--secondary, #F8F7F7);
  font-family: Playfair Display;
  font-size: 5.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.6rem;
  /* 105.66% */
}
#page_house .section_banner--container .title strong {
  color: var(--secondary, #F8F7F7);
  font-family: Betterlett;
  font-size: 9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 8.3rem;
  /* 92.222% */
}
@media (max-width: 500px) {
  #page_house .section_banner--container .title {
    max-width: 80%;
  }
}
#page_house .section_food-slider {
  display: flex;
  padding: 90px 0px 90px;
}
@media (max-width: 1100px) {
  #page_house .section_food-slider {
    padding: 90px 0px 0px;
  }
}
#page_house .section_food-slider .title {
  color: var(--default-black, #222);
  font-family: "Playfair Display";
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 136.364% */
  margin-bottom: 30px;
  padding-left: 22px;
}
@media (max-width: 1100px) {
  #page_house .section_food-slider .title {
    padding-left: 3px;
  }
}
#page_house .section_food-slider--container {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
#page_house .section_food-slider .foodSwiper {
  max-width: 100% !important;
  min-width: 100% !important;
}
@media (max-width: 1100px) {
  #page_house .section_food-slider .foodSwiper .swiper-button-prev {
    left: 0;
  }
}
#page_house .section_food-slider .foodSwiper .swiper-wrapper {
  padding-bottom: 4.5rem;
}
@media (max-width: 1100px) {
  #page_house .section_food-slider .foodSwiper .swiper-wrapper {
    padding-bottom: 0;
  }
}
#page_house .section_food-slider .foodSwiper .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 38px;
  transform: scale(1.2);
}
#page_house .section_food-slider .foodSwiper .swiper-slide .image {
  width: 80%;
  aspect-ratio: 2.3;
  object-fit: cover;
  object-position: center;
}
#page_house .section_food-slider .foodSwiper .swiper-slide .description {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #5a5a5a;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  max-width: 60%;
}
#page_house .section_food-slider .foodSwiper .swiper-button-prev, #page_house .section_food-slider .foodSwiper .swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  aspect-ratio: 1 !important;
  border-radius: 50%;
  background-color: rgba(70, 77, 69, 0.2588235294);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 !important;
  transition: all 0.3s ease-out;
}
#page_house .section_food-slider .foodSwiper .swiper-button-prev::after, #page_house .section_food-slider .foodSwiper .swiper-button-prev::before, #page_house .section_food-slider .foodSwiper .swiper-button-next::after, #page_house .section_food-slider .foodSwiper .swiper-button-next::before {
  display: none;
}
@media (max-width: 1100px) {
  #page_house .section_food-slider .foodSwiper .swiper-button-prev, #page_house .section_food-slider .foodSwiper .swiper-button-next {
    top: 55px;
  }
}
#page_house .section_food-slider .foodSwiper .swiper-button-prev:hover, #page_house .section_food-slider .foodSwiper .swiper-button-next:hover {
  background: #748173;
}
#page_house .section_food-slider .foodSwiper .swiper-button-prev ion-icon, #page_house .section_food-slider .foodSwiper .swiper-button-next ion-icon {
  font-size: 30px;
  color: white;
}
#page_house .section_food-slider .foodSwiper .swiper-button-next {
  right: 0px;
}
#page_house .section_food-slider .foodSwiper .swiper-button-left {
  left: 0px;
}
#page_house .cards {
  margin-top: 30px;
}
@media (max-width: 1100px) {
  #page_house .cards {
    width: 100%;
    padding: 20px 0px;
  }
}
#page_house .card__house {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 20px 0px rgba(90, 90, 90, 0.1);
  border-bottom: 6px solid #748173;
  height: auto;
  flex-direction: column;
  gap: 1.6rem;
  padding-bottom: 1rem;
  padding-inline: 1rem;
}
@media (max-width: 1100px) {
  #page_house .card__house {
    border-bottom: none;
  }
}
#page_house .card__house .title {
  font-size: 2.2rem;
  line-height: 1.1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
}
#page_house .card__house .text {
  font-size: 1.4rem;
  line-height: 16px;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  max-width: 218px;
}
#page_house .motiva {
  margin-top: 50px;
}
@media (max-width: 1100px) {
  #page_house .motiva {
    margin-top: 20px;
  }
}
#page_house .motiva .title {
  font-size: 4rem;
  line-height: 45px;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
#page_house .motiva .title strong {
  font-family: "Betterlett";
  color: #748173;
  font-size: 7.3rem;
  font-weight: 400;
}
#page_house .motiva .text {
  font-size: 1.6rem;
  line-height: 30px;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
  margin-top: 60px;
}
#page_house .motiva .content-image {
  aspect-ratio: 0.87;
}
#page_house .motiva .content-image img {
  transition: 0.3s ease-out all;
  height: 102%;
}
#page_house .motiva .content-image:hover img {
  transform: scale(1.1);
}
#page_house .structure {
  margin-top: 10rem;
  height: 434px;
  position: relative;
  padding-top: 10.6rem;
}
@media (max-width: 1100px) {
  #page_house .structure {
    margin-top: 8.4rem;
    padding-top: 8.4rem;
  }
}
#page_house .structure::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
  background: #222222;
  opacity: 0.5;
}
#page_house .structure .bg-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
#page_house .structure .title {
  font-size: 5.3rem;
  line-height: 56px;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
}
#page_house .structure .title strong {
  font-family: "Betterlett";
  font-size: 7.3rem;
  line-height: 8.3rem;
  font-weight: 400;
}
#page_house .structure .text {
  font-size: 1.6rem;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
}
#page_house .structure__cards {
  position: relative;
  top: -85px;
}
@media (max-width: 1100px) {
  #page_house .structure__cards {
    top: -20px;
  }
}
#page_house .structure__cards .title {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 136.364% */
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 1100px) {
  #page_house .structure__cards .title {
    text-align: center;
  }
}
#page_house .structure__cards .text {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}
@media (max-width: 1100px) {
  #page_house .structure__cards .text {
    text-align: center;
  }
}
#page_house .structure__cards .link-structure {
  color: var(--foundation-primary-normal, #748173);
  text-align: center;
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  /* 343.75% */
  letter-spacing: -0.64px;
  display: flex;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  border: 1px solid var(--foundation-primary-normal, #748173);
  text-decoration: none;
  margin-top: 32px;
  transition: all 0.3s ease;
}
#page_house .structure__cards .link-structure:hover {
  color: #fff;
  background: #748173;
  border-color: #748173;
}
#page_house .structure__cards .card-structure {
  margin-bottom: 30px;
  display: flex;
  max-width: 590px;
  height: 352px;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}
@media (max-width: 1100px) {
  #page_house .structure__cards .card-structure {
    align-items: center;
    min-height: 352px;
    height: auto;
  }
}
#page_house .structure__cards .card-structure .icon {
  margin-top: 38px;
  width: 40px;
  height: 40px;
}
#page_house .structure__cards .card-white {
  border-bottom: 6px solid #748173;
  background: #fff;
}
#page_house .structure__cards .card-brown {
  background: #748173;
}
#page_house .structure__cards .card-brown .title {
  color: #fff;
}
#page_house .structure__cards .card-brown .text {
  color: #fff;
}
#page_house .structure__cards .card-brown .link-structure {
  background: #fff;
  border-color: #fff;
  color: #748173;
}
#page_house .structure__cards .card-brown .link-structure:hover {
  color: #fff;
  background: #748173;
  border-color: #fff;
}
#page_house .structure__cards .card-brow-btn .icon, #page_house .structure__cards .card-white-btn .icon {
  margin-top: 13.5px;
}
#page_house .story {
  margin-top: 12rem;
  padding-top: 9rem;
  position: relative;
}
@media (max-width: 1100px) {
  #page_house .story {
    padding-top: 6rem;
    margin-top: 0;
  }
}
#page_house .story::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 60%;
  height: 292px;
  background: #748173;
}
@media (max-width: 1600px) {
  #page_house .story::after {
    height: 230px;
  }
}
@media (max-width: 1100px) {
  #page_house .story::after {
    width: 100%;
    height: 220px;
  }
}
#page_house .story .img-1 {
  position: absolute;
  right: 0;
  top: 103px;
  aspect-ratio: 1.2;
  width: 50%;
  overflow: hidden;
  max-height: 600px;
}
#page_house .story .img-1 img {
  transition: all 0.3s ease-out;
}
#page_house .story .img-1:hover img {
  transform: scale(1.1);
}
@media (max-width: 1100px) {
  #page_house .story .img-1 {
    display: none;
  }
}
#page_house .story .img-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  aspect-ratio: 1.2;
  width: 45%;
  overflow: hidden;
  max-height: 600px;
}
#page_house .story .img-2 img {
  transition: all 0.3s ease-out;
}
#page_house .story .img-2:hover img {
  transform: scale(1.1);
}
@media (max-width: 1100px) {
  #page_house .story .img-2 {
    display: none;
  }
}
#page_house .story .mobile-img-1, #page_house .story .mobile-img-2 {
  display: none;
}
@media (max-width: 1100px) {
  #page_house .story .mobile-img-1, #page_house .story .mobile-img-2 {
    display: block;
    width: 100%;
    max-height: 493px;
    object-fit: cover;
  }
}
#page_house .story .title {
  font-size: 5.3rem;
  line-height: 56px;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: left;
  position: relative;
  z-index: 9;
}
#page_house .story .title strong {
  font-family: "Betterlett";
  font-size: 7.3rem;
  line-height: 8.3rem;
  font-weight: 400;
}
#page_house .story .text {
  margin-top: 80px;
  font-size: 1.6rem;
  line-height: 30px;
  color: #5A5A5A;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: left;
}
@media (max-width: 1300px) {
  #page_house .story .text {
    margin-top: 50px;
  }
}
@media (max-width: 1100px) {
  #page_house .story .text {
    margin-top: 7rem;
  }
}

@media (max-width: 1100px) {
  #page_events-corporate .section_banner .title {
    color: var(--default-white, #FFF);
    /* title */
    font-family: Playfair Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 117.857% */
  }
  #page_events-corporate .section_banner .title strong {
    color: var(--default-white, #FFF);
    font-family: Betterlett;
    font-size: 63px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    /* 95.238% */
  }
}

@media (max-width: 1100px) {
  #page_gastronomy .fixed_icons {
    display: none;
  }
}
#page_gastronomy .section_food-slider {
  display: flex;
  padding: 90px 0px 128px;
}
#page_gastronomy .section_food-slider--container {
  width: 95%;
  max-width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
#page_gastronomy .section_food-slider .foodSwiper {
  max-width: 100% !important;
  min-width: 100% !important;
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 38px;
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-slide .image {
  width: 80%;
  aspect-ratio: 2.3;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1100px) {
  #page_gastronomy .section_food-slider .foodSwiper .swiper-slide .image {
    width: 100%;
    aspect-ratio: 1.9;
  }
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-slide .description {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #5a5a5a;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  max-width: 60%;
}
@media (max-width: 1100px) {
  #page_gastronomy .section_food-slider .foodSwiper .swiper-slide .description {
    max-width: 100%;
  }
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-button-prev, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-next {
  width: 50px !important;
  height: 50px !important;
  aspect-ratio: 1 !important;
  border-radius: 50%;
  background-color: rgba(70, 77, 69, 0.2588235294);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0 !important;
  transition: all 0.3s ease-out;
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-button-prev::after, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-prev::before, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-next::after, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-next::before {
  display: none;
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-button-prev ion-icon, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-next ion-icon {
  font-size: 30px;
  color: white;
}
#page_gastronomy .section_food-slider .foodSwiper .swiper-button-prev:hover, #page_gastronomy .section_food-slider .foodSwiper .swiper-button-next:hover {
  background: #748173;
}

#page_contact .image-wrap:hover img {
  transform: scale(1.1);
}
#page_contact .image-wrap img {
  transition: all 0.3s ease !important;
}
#page_contact .mapa {
  width: 100%;
  height: 100%;
}
#page_contact .section_banner .title {
  font-size: 7rem;
  line-height: 8.5rem;
  max-width: inherit;
}
#page_contact .section_banner .title strong {
  font-size: 11rem;
  line-height: 9rem;
}
@media (max-width: 1100px) {
  #page_contact .section_banner .title {
    color: var(--default-white, #FFF);
    /* title */
    font-family: Playfair Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 117.857% */
    max-width: 80%;
  }
}
#page_contact .section_banner .title strong {
  color: var(--default-white, #FFF);
  font-family: Betterlett;
  font-size: 63px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 95.238% */
}
#page_contact .section_forms {
  padding: 50px 0px 100px;
  display: flex;
  overflow: hidden;
}
#page_contact .section_forms--container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}
@media (max-width: 1100px) {
  #page_contact .section_forms--container {
    width: 100%;
    padding-inline: 15px;
  }
}
#page_contact .section_forms .title {
  font-size: 4rem;
  line-height: 1.1;
  color: #222222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
  max-width: 65%;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .title {
    max-width: 100%;
    font-size: 2.8rem;
  }
}
#page_contact .section_forms .description {
  margin-top: 30px;
  font-size: 2.2rem;
  line-height: 1.5;
  color: #222222;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
  max-width: 65%;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .description {
    max-width: 100%;
    font-size: 1.6rem;
  }
}
#page_contact .section_forms .box_content {
  display: grid;
  grid-template-columns: 34% 1fr;
  gap: 100px;
  margin-top: 113px;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .box_content {
    grid-template-columns: 1fr;
    margin-top: 60px;
  }
}
#page_contact .section_forms .box_content-image {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .box_content-image {
    display: none;
  }
}
#page_contact .section_forms .box_content-image .reveal {
  position: absolute;
  transform: translate(-48%);
  width: 190%;
  height: 87%;
}
#page_contact .section_forms .box_content-image .double {
  display: grid;
  aspect-ratio: 1.15;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  counter-increment: section;
  height: 100% !important;
  width: 100% !important;
}
#page_contact .section_forms .box_content-image .double__img {
  grid-area: 1/1/-1/-1;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}
#page_contact .section_forms .box_content form {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .box_content form {
    gap: 21px;
  }
}
#page_contact .section_forms .box_content form .input-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#page_contact .section_forms .box_content form .input-group label {
  font-size: 1.5rem;
  line-height: 1;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
#page_contact .section_forms .box_content form .input-group input,
#page_contact .section_forms .box_content form .input-group textarea {
  width: 100%;
  padding: 24px 19px;
  border: 1px solid #748173;
  background-color: transparent;
  font-size: 1.5rem;
  line-height: 1;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
#page_contact .section_forms .box_content form .input-group .error--message {
  display: none;
}
#page_contact .section_forms .box_content form .input-group.show--error input {
  color: red;
  border: 1px solid red;
}
#page_contact .section_forms .box_content form .input-group.show--error input::placeholder {
  color: red;
}
#page_contact .section_forms .box_content form .input-group textarea {
  min-height: 239px;
}
#page_contact .section_forms .box_content form .buttons-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
#page_contact .section_forms .box_content form .buttons-group .label {
  font-size: 1.5rem;
  line-height: 1;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
#page_contact .section_forms .box_content form .buttons-group .box_button {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .box_content form .buttons-group .box_button {
    grid-template-columns: 1fr;
  }
}
#page_contact .section_forms .box_content form .buttons-group .box_button button {
  width: 100%;
  padding: 24px 19px;
  border: 1px solid #748173;
  background-color: transparent;
  font-size: 1.5rem;
  line-height: 1;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
#page_contact .section_forms .box_content form .info-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 1100px) {
  #page_contact .section_forms .box_content form .info-group {
    grid-template-columns: 1fr;
  }
}
#page_contact .section_forms .box_content form .terms-group {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  align-items: center;
  margin-top: 17px;
}
#page_contact .section_forms .box_content form .terms-group .checkbox {
  background: transparent;
  display: block;
  width: 17px;
  height: 17px;
  border: solid 2px #748173;
  position: relative;
  cursor: pointer;
}
#page_contact .section_forms .box_content form .terms-group .checkbox > input {
  opacity: 0;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#page_contact .section_forms .box_content form .terms-group .checkbox .check {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -3px;
  clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
  transition: 0.4s cubic-bezier(0.8, 0, 0.2, 1);
  transition-property: background-color, clip-path, border-color;
  transition-delay: 0.1s, 0s;
}
#page_contact .section_forms .box_content form .terms-group .checkbox input:checked ~ .check {
  background-color: #748173;
  border-color: transparent;
  transition-delay: 0s;
  clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);
}
#page_contact .section_forms .box_content form .terms-group label {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #748173;
  font-weight: 300;
  font-family: "Montserrat";
  text-align: start;
}
#page_contact .section_forms .box_content form .terms-group label a {
  text-decoration: underline;
}
#page_contact .section_forms .box_content form .terms-group .error--message {
  display: none;
}
#page_contact .section_forms .box_content form .terms-group.show--error label {
  color: red;
}
#page_contact .section_forms .box_content form button[type=submit] {
  padding: 20px 48px;
  border-radius: 100px;
  width: fit-content;
  transition: 0.4s;
  background: #748173;
  border: 1px solid #748173;
  font-size: 1.6rem;
  line-height: 1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: start;
  transition: all 0.3s ease-out;
}
#page_contact .section_forms .box_content form button[type=submit]:hover {
  background-color: #fff;
  color: #748173;
}
#page_contact .section_forms .box_content form button[type=submit]:focus {
  background-color: #464d45;
}
#page_contact .section_location {
  display: flex;
  overflow: hidden;
  width: 100%;
}
@media (max-width: 1100px) {
  #page_contact .section_location {
    padding-bottom: 0;
  }
}
#page_contact .section_location--container {
  width: 100%;
  max-width: 1920px;
  display: grid;
  grid-template-columns: 1fr 55%;
  margin: 0 auto;
  align-items: start;
}
@media (max-width: 1100px) {
  #page_contact .section_location--container {
    display: flex;
    flex-direction: column-reverse;
  }
}
#page_contact .section_location--container .title {
  display: none;
}
@media (max-width: 1100px) {
  #page_contact .section_location--container .title {
    display: block;
    text-align: center;
    margin-bottom: 30px;
    color: #464d45;
    /* title */
    font-family: "Playfair Display";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 117.857% */
    order: 1;
  }
}
#page_contact .section_location .box_maps {
  width: 110%;
  position: relative;
  z-index: 0;
  background-color: red;
  aspect-ratio: 1.4;
}
@media (max-width: 1100px) {
  #page_contact .section_location .box_maps {
    order: 0;
  }
}
#page_contact .section_location .box_maps img {
  width: 100%;
  height: 100%;
}
#page_contact .section_location .box_content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 2;
}
@media (max-width: 1100px) {
  #page_contact .section_location .box_content {
    order: 3;
  }
}
#page_contact .section_location .group-contact {
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
  background-color: #748173;
  padding: 90px 18% 87px;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-contact {
    align-items: center;
  }
}
#page_contact .section_location .group-contact-title {
  font-size: 5.3rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  width: fit-content;
}
#page_contact .section_location .group-contact-title strong {
  font-family: "Betterlett";
  line-height: 1.2;
  font-size: 7.3rem;
  font-weight: 500;
}
#page_contact .section_location .group-contact-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#page_contact .section_location .group-contact-list li {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-contact-list li {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
#page_contact .section_location .group-contact-list li ion-icon {
  font-size: 2.7rem;
  color: white;
}
#page_contact .section_location .group-contact-list li p {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #fff;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-contact-list li p {
    text-align: center;
  }
}
#page_contact .section_location .group-service {
  display: flex;
  flex-direction: column;
  gap: 27px;
  width: 100%;
  padding: 81px 17% 40px;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-service {
    align-items: center;
  }
}
#page_contact .section_location .group-service-title {
  font-size: 5.3rem;
  line-height: 1.1;
  color: #222;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  width: fit-content;
}
#page_contact .section_location .group-service-title strong {
  font-family: "Betterlett";
  line-height: 1.2;
  font-size: 7.3rem;
  font-weight: 500;
  color: #748173;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-service-title {
    text-align: center;
    font-size: 3.5rem;
  }
  #page_contact .section_location .group-service-title strong {
    font-size: 7rem;
  }
}
#page_contact .section_location .group-service-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#page_contact .section_location .group-service-list li {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-service-list li {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
#page_contact .section_location .group-service-list li ion-icon {
  font-size: 2.7rem;
  color: #748173;
}
#page_contact .section_location .group-service-list li p {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-service-list li p {
    text-align: center;
  }
}
#page_contact .section_location .group-service-description {
  font-size: 1.6rem;
  line-height: 1.9;
  color: #748173;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: start;
}
@media (max-width: 1100px) {
  #page_contact .section_location .group-service-description {
    text-align: center;
  }
}
#page_contact .section_valuate {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 158px 0px 166px;
  display: flex;
  overflow: hidden;
}
#page_contact .section_valuate--container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 17%;
}
#page_contact .section_valuate .box_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
#page_contact .section_valuate .box_content-title {
  font-size: 4rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  width: fit-content;
}
#page_contact .section_valuate .box_content-title strong {
  font-family: "Betterlett";
  line-height: 1.2;
  font-size: 6.2rem;
  font-weight: 500;
}
#page_contact .section_valuate .box_content-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
#page_contact .section_valuate .box_content-icons a {
  background-color: white;
  width: 55px;
  height: 55px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
#page_contact .section_valuate .box_content-icons a:hover {
  background-color: #748173;
}
#page_contact .section_valuate .box_content-icons a:hover ion-icon {
  color: #fff;
}
#page_contact .section_valuate .box_content-icons ion-icon {
  font-size: 3rem;
  color: #748173;
  transition: all 0.3s ease-out;
}
#page_contact .section_valuate .diviser {
  height: 140%;
  transform: translateY(-15%);
  width: 2px;
  background-color: white;
}

#blog .destaque {
  display: none;
}
@media (max-width: 1100px) {
  #blog .destaque {
    margin-top: -70px;
    margin-bottom: 50px;
    display: block;
  }
  #blog .destaque h2 {
    display: none;
  }
}
#blog .section_banner .title {
  color: var(--default-white, #FFF);
  font-family: Playfair Display;
  font-size: 7rem;
  font-style: normal;
  font-weight: 400;
  line-height: 8rem;
  /* 114.286% */
}
#blog .section_banner .title strong {
  color: var(--default-white, #FFF);
  font-family: Betterlett;
  font-size: 11rem;
  font-style: normal;
  font-weight: 400;
  line-height: 10rem;
  /* 90.909% */
}
@media (max-width: 1100px) {
  #blog .section_banner .title {
    color: var(--default-white, #FFF);
    font-family: Playfair Display;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    /* 111.111% */
    max-width: 80%;
  }
  #blog .section_banner .title strong {
    color: var(--default-white, #FFF);
    font-family: Betterlett;
    font-size: 63px;
    font-style: normal;
    font-weight: 400;
    line-height: 60px;
    /* 95.238% */
  }
}
#blog .description {
  display: none;
}
#blog .see-more-galley-images {
  display: none;
}
#blog .container {
  max-width: 1196px;
}
#blog .title-filter {
  color: #464D45;
  font-family: "Playfair Display";
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 8rem;
  /* 114.286% */
  margin-bottom: 50px;
}
#blog .filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 80px;
}
@media (max-width: 1100px) {
  #blog .filter {
    grid-template-columns: 100px 100px 1fr;
    display: grid;
    gap: 10px;
    margin-bottom: 100px;
  }
}
#blog .filter .filter-item {
  display: flex;
  padding: 0px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  background: var(--foundation-primary-dark-hover, #fff);
  color: var(--default-white, #748173);
  text-align: center;
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  /* 343.75% */
  letter-spacing: -0.64px;
  text-decoration: none;
  border: 1px solid #748173;
}
#blog .filter .filter-item.active {
  background: var(--foundation-primary-dark-hover, #464D45);
  color: var(--default-white, #FFF);
}
#blog .filter .filter-item:nth-child(4) {
  grid-column: span 2;
}
#blog .filter .filter-item:nth-child(5) {
  grid-column: span 1;
}
#blog .filter .filter-item:nth-child(6) {
  grid-column: span 3;
}
#blog .post {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-items: center;
}
#blog .post img {
  width: 100%;
  height: 490px;
  object-fit: cover;
}
@media (max-width: 1100px) {
  #blog .post img {
    height: 410px;
  }
}
#blog .post .body {
  width: calc(100% - 30px);
  background: #fff;
  padding: 30px;
  position: relative;
  border-bottom: 6px solid #748173;
  top: -100px;
  margin-bottom: -65px;
}
#blog .post .title {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 136.364% */
}
#blog .post .date {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 187.5% */
  margin: 16px 0;
}
#blog .post .text {
  overflow: hidden;
  color: var(--bodyText, #5A5A5A);
  text-overflow: ellipsis;
  whitespace: nowrap;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}
#blog .post .post-btn {
  display: flex;
  padding: 0px 50px;
  justify-content: flex-start;
  align-items: center;
  color: var(--foundation-primary-normal, #748173);
  text-align: center;
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 55px;
  /* 343.75% */
  letter-spacing: -0.64px;
  border-radius: 34px;
  border: 1px solid var(--foundation-primary-normal, #748173);
  width: fit-content;
  text-decoration: none;
  transition: 0.3s ease-out all;
  position: relative;
  z-index: 9;
  margin-top: 30px;
}
#blog .post .post-btn:hover {
  background: var(--foundation-primary-normal, #748173);
  color: #fff;
}
#blog .side-post {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1100px) {
  #blog .side-post {
    display: none;
  }
}
#blog .side-post img {
  height: 320px;
  object-fit: cover;
  width: 100%;
}
#blog .side-post .body {
  width: calc(100% - 6px);
  background: #fff;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 7px;
  border-bottom: 6px solid #748173;
  top: -60px;
  position: relative;
  margin-bottom: -33px;
}
#blog .side-post .title {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}
#blog .side-post .date {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 187.5% */
}
#blog .pagination {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding-left: 38px;
  margin-top: 60px;
  margin-bottom: 120px;
}
@media (max-width: 1100px) {
  #blog .pagination {
    padding-left: 0;
    margin-top: 0;
  }
}
#blog .pagination a {
  color: var(--bodyText, #5A5A5A);
  font-family: Playfair Display;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 136.364% */
  letter-spacing: -0.88px;
  text-decoration: none;
  position: relative;
}
#blog .pagination a.active::after {
  content: "";
  position: absolute;
  left: -3px;
  bottom: -2px;
  height: 2px;
  width: 180%;
  background: #748173;
}
#blog .seta {
  margin-top: 60px;
  position: relative;
}
#blog .seta svg {
  transition: 0.3s ease-out all;
}
#blog .seta svg #circle {
  transition: 0.3s ease-out all;
}
#blog .seta svg #arrow {
  transition: 0.3s ease-out all;
}
#blog .seta svg:hover #circle {
  fill: #748173;
}
#blog .seta svg:hover #arrow {
  fill: #fff;
}
@media (max-width: 1100px) {
  #blog .seta-topo {
    transform: translate(37vw, -5vh);
  }
}

.section_valuate {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 158px 0px 166px;
  display: flex;
  overflow: hidden;
}
.section_valuate--container {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 17%;
}
.section_valuate .box_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.section_valuate .box_content-title {
  font-size: 4rem;
  line-height: 1.1;
  color: #fff;
  font-weight: 400;
  font-family: "Playfair Display";
  text-align: center;
  width: fit-content;
}
.section_valuate .box_content-title strong {
  font-family: "Betterlett";
  line-height: 1.2;
  font-size: 6.2rem;
  font-weight: 500;
}
.section_valuate .box_content-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.section_valuate .box_content-icons a {
  background-color: white;
  width: 55px;
  height: 55px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
}
.section_valuate .box_content-icons a:hover {
  background-color: #748173;
}
.section_valuate .box_content-icons a:hover ion-icon {
  color: #fff;
}
.section_valuate .box_content-icons ion-icon {
  font-size: 3rem;
  color: #748173;
  transition: all 0.3s ease-out;
}
.section_valuate .diviser {
  height: 140%;
  transform: translateY(-15%);
  width: 2px;
  background-color: white;
}

#experience .lupa {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0;
  z-index: 9;
}
#experience .section_banner .title {
  font-size: 5.3rem;
  line-height: 5.6rem;
}
#experience .section_banner .title strong {
  font-size: 7.7rem;
  line-height: normal;
}
@media (max-width: 1100px) {
  #experience .section_banner .title {
    max-width: 63%;
    font-size: 2.8rem;
    line-height: 33px;
  }
  #experience .section_banner .title strong {
    font-size: 5.4rem;
  }
}
#experience .description {
  display: none;
}
#experience .see-more-galley-images {
  display: none;
}
#experience .section_banner .title {
  align-self: flex-end;
}
#experience .fixed_icons {
  right: inherit;
  left: 0;
  top: 30vh;
}
@media (max-width: 1100px) {
  #experience .fixed_icons {
    left: 22px;
    top: 22vh;
  }
}
#experience .memory {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
@media (max-width: 1100px) {
  #experience .memory {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
}
#experience .memory .title {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  /* 112.5% */
}
@media (max-width: 1100px) {
  #experience .memory .title {
    font-size: 2.8rem;
    line-height: 3.3rem;
    max-width: 85%;
  }
  #experience .memory .title br {
    display: none;
  }
}
#experience .memory .title strong {
  color: var(--foundation-primary-normal, #748173);
  font-family: Betterlett;
  font-size: 9rem;
  font-style: normal;
  font-weight: 400;
  line-height: 10rem;
  /* 111.111% */
}
@media (max-width: 1100px) {
  #experience .memory .title strong {
    font-size: 6.5rem;
    line-height: 6rem;
    display: block;
  }
}
#experience .memory .text {
  margin-top: 43px;
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 200% */
}
#experience .mission {
  position: relative;
}
#experience .mission .mission-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  aspect-ratio: 1.98;
  width: 55%;
  height: 390px;
}
#experience .mission .mission-img img {
  transition: all 0.3s ease-out;
}
#experience .mission .mission-img:hover svg {
  opacity: 1;
}
#experience .mission .mission-img:hover img {
  transform: scale(1.1);
}
@media (max-width: 1100px) {
  #experience .mission .mission-img {
    display: none;
  }
}
#experience .mission .mobile-only {
  display: none;
}
@media (max-width: 1100px) {
  #experience .mission .mobile-only {
    display: block;
    z-index: 999;
    position: relative;
  }
}
#experience .mission .box-1 {
  margin-top: 120px;
}
@media (max-width: 1100px) {
  #experience .mission .box-1 {
    margin-top: 60px;
    margin-bottom: 34px;
  }
}
#experience .mission .box-1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 390px;
  width: 50%;
  background: var(--foundation-primary-normal, #748173);
  z-index: 2;
}
@media (max-width: 1100px) {
  #experience .mission .box-1::after {
    width: 100%;
    height: 253px;
  }
}
#experience .mission .box-1 h2 {
  color: var(--default-white, #FFF);
  /* title */
  font-family: Playfair Display;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  /* 112.5% */
  position: relative;
  z-index: 3;
}
#experience .mission .box-1 p {
  color: var(--default-white, #FFF);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 200% */
  position: relative;
  z-index: 3;
}
#experience .mission .box-1 p strong {
  color: var(--default-white, #FFF);
  font-family: Betterlett;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 7rem;
  /* 116.667% */
}
@media (max-width: 1100px) {
  #experience .mission .box-1 p strong {
    line-height: 4rem;
  }
}
#experience .mission .box-2 {
  margin-top: 240px;
}
@media (max-width: 1100px) {
  #experience .mission .box-2 {
    margin-top: 60px;
  }
}
#experience .mission .box-2 h2 {
  color: var(--default-black, #222);
  /* title */
  font-family: Playfair Display;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  /* 112.5% */
}
#experience .mission .box-2 p {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 200% */
}
@media (max-width: 1100px) {
  #experience .mission .box-2 p {
    margin-top: 16px;
  }
}
#experience .cardsExpirence {
  margin-top: 120px;
}
@media (max-width: 1100px) {
  #experience .cardsExpirence {
    margin-top: 60px;
  }
}
@media (max-width: 1100px) {
  #experience .cardsExpirence .box-cards {
    width: 100%;
    overflow-x: scroll;
  }
  #experience .cardsExpirence .box-cards .row {
    flex-wrap: nowrap;
  }
}
#experience .cardsExpirence .card-expirence {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#experience .cardsExpirence .card-expirence .wrapper-img {
  width: 100%;
  height: 390px;
  overflow: hidden;
}
@media (max-width: 1100px) {
  #experience .cardsExpirence .card-expirence .wrapper-img {
    height: 364px;
  }
}
#experience .cardsExpirence .card-expirence img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  object-fit: cover;
}
#experience .cardsExpirence .card-expirence:hover img {
  transform: scale(1.1);
}
#experience .cardsExpirence .card-expirence .body {
  background: #fff;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - 30px);
  top: -122px;
  position: relative;
  background: var(--default-white, #FFF);
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
  border-bottom: 6px solid #748173;
}
@media (max-width: 1100px) {
  #experience .cardsExpirence .card-expirence .body {
    padding: 30px 21px;
    margin-bottom: -62px;
  }
}
#experience .cardsExpirence .card-expirence .body h2 {
  color: var(--default-black, #222);
  font-family: Playfair Display;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  /* 136.364% */
  margin-top: 16px;
}
#experience .cardsExpirence .card-expirence .body p {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 187.5% */
}
#experience .cardsExpirence .card-expirence .body a {
  text-decoration: none;
  color: var(--foundation-primary-normal, #748173);
  text-align: center;
  font-family: Playfair Display;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5.5rem;
  /* 343.75% */
  letter-spacing: -0.64px;
  display: inline-flex;
  padding: 0px 50px;
  justify-content: center;
  align-items: center;
  border-radius: 34px;
  border: 1px solid var(--foundation-primary-normal, #748173);
  margin-top: 30px;
  transition: all 0.3s ease-out;
}
#experience .cardsExpirence .card-expirence .body a:hover {
  background: var(--foundation-primary-normal, #748173);
  color: #fff;
}
#experience .drink, #experience .wine {
  padding-top: 120px;
  background: var(--foundation-primary-normal, #748173);
  padding-bottom: 100px;
}
@media (max-width: 1100px) {
  #experience .drink, #experience .wine {
    margin-top: 0;
    padding-top: 84px;
    padding-bottom: 84px;
  }
}
#experience .drink .drinkSwiper, #experience .drink .coffeSwiper, #experience .wine .drinkSwiper, #experience .wine .coffeSwiper {
  width: 100%;
  min-height: 588px;
}
@media (max-width: 1100px) {
  #experience .drink .drinkSwiper, #experience .drink .coffeSwiper, #experience .wine .drinkSwiper, #experience .wine .coffeSwiper {
    min-height: 510px;
  }
}
#experience .drink .drinkSwiper .swiper-wrapper, #experience .drink .coffeSwiper .swiper-wrapper, #experience .wine .drinkSwiper .swiper-wrapper, #experience .wine .coffeSwiper .swiper-wrapper {
  padding-bottom: 60px;
}
@media (max-width: 1100px) {
  #experience .drink .drinkSwiper .swiper-wrapper, #experience .drink .coffeSwiper .swiper-wrapper, #experience .wine .drinkSwiper .swiper-wrapper, #experience .wine .coffeSwiper .swiper-wrapper {
    padding-bottom: 0;
  }
}
#experience .drink .drinkSwiper .content-image, #experience .drink .coffeSwiper .content-image, #experience .wine .drinkSwiper .content-image, #experience .wine .coffeSwiper .content-image {
  aspect-ratio: 0.81;
}
#experience .drink .drinkSwiper .content-image:hover .lupa, #experience .drink .coffeSwiper .content-image:hover .lupa, #experience .wine .drinkSwiper .content-image:hover .lupa, #experience .wine .coffeSwiper .content-image:hover .lupa {
  opacity: 1;
}
#experience .drink .drinkSwiper .content-image:hover img, #experience .drink .coffeSwiper .content-image:hover img, #experience .wine .drinkSwiper .content-image:hover img, #experience .wine .coffeSwiper .content-image:hover img {
  transform: scale(1.1);
}
#experience .drink .drinkSwiper img, #experience .drink .coffeSwiper img, #experience .wine .drinkSwiper img, #experience .wine .coffeSwiper img {
  object-fit: cover;
  width: 100%;
  transition: all 0.3s ease-out;
}
#experience .drink .drinkSwiper .swiper-horizontal > .swiper-pagination-bullets, #experience .drink .drinkSwiper .swiper-pagination-bullets.swiper-pagination-horizontal, #experience .drink .drinkSwiper .swiper-pagination-custom, #experience .drink .drinkSwiper .swiper-pagination-fraction, #experience .drink .coffeSwiper .swiper-horizontal > .swiper-pagination-bullets, #experience .drink .coffeSwiper .swiper-pagination-bullets.swiper-pagination-horizontal, #experience .drink .coffeSwiper .swiper-pagination-custom, #experience .drink .coffeSwiper .swiper-pagination-fraction, #experience .wine .drinkSwiper .swiper-horizontal > .swiper-pagination-bullets, #experience .wine .drinkSwiper .swiper-pagination-bullets.swiper-pagination-horizontal, #experience .wine .drinkSwiper .swiper-pagination-custom, #experience .wine .drinkSwiper .swiper-pagination-fraction, #experience .wine .coffeSwiper .swiper-horizontal > .swiper-pagination-bullets, #experience .wine .coffeSwiper .swiper-pagination-bullets.swiper-pagination-horizontal, #experience .wine .coffeSwiper .swiper-pagination-custom, #experience .wine .coffeSwiper .swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 16px);
}
#experience .drink .drinkSwiper .swiper-pagination-bullet, #experience .drink .coffeSwiper .swiper-pagination-bullet, #experience .wine .drinkSwiper .swiper-pagination-bullet, #experience .wine .coffeSwiper .swiper-pagination-bullet {
  background: #fff;
  outline: 1px solid #fff;
  opacity: 1;
}
#experience .drink .drinkSwiper .swiper-pagination-bullet-active, #experience .drink .coffeSwiper .swiper-pagination-bullet-active, #experience .wine .drinkSwiper .swiper-pagination-bullet-active, #experience .wine .coffeSwiper .swiper-pagination-bullet-active {
  background: transparent;
  outline: 1px solid #fff;
}
#experience .drink .content .title, #experience .wine .content .title {
  color: var(--default-white, #FFF);
  /* title */
  font-family: Playfair Display;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem;
  /* 112.5% */
}
#experience .drink .content .subtitle, #experience .wine .content .subtitle {
  color: var(--default-white, #FFF);
  font-family: Montserrat;
  font-size: 2.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 136.364% */
}
#experience .drink .content .subtitle strong, #experience .wine .content .subtitle strong {
  color: var(--default-white, #FFF);
  font-family: Betterlett;
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5rem;
  /* 125% */
}
#experience .drink .content .text, #experience .wine .content .text {
  color: var(--default-white, #FFF);
  font-family: Montserrat;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 3rem;
  /* 200% */
  margin-top: 60px;
}
#experience .wine {
  background: #fff;
}
#experience .wine .text {
  color: var(--bodyText, #5A5A5A) !important;
}
#experience .wine .subtitle {
  color: var(--bodyText, #5A5A5A) !important;
}
#experience .wine .title {
  color: var(--bodyText, #5A5A5A) !important;
}

@media (max-width: 1100px) {
  #blogDetail .fixed_icons {
    top: 23%;
  }
}
#blogDetail .blog-detail-content {
  margin-top: 30px;
}
#blogDetail .blog-detail-content span {
  color: var(--default-white, #FFF);
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}
@media (max-width: 1100px) {
  #blogDetail .blog-detail-content span {
    color: var(--default-white, #FFF);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 200% */
  }
}
#blogDetail .section_banner {
  background-image: none !important;
  background-color: var(--foundation-primary-normal, #748173);
}
#blogDetail .section_banner::after {
  display: none;
}
#blogDetail .section_banner .title {
  color: var(--default-white, #FFF);
  font-family: Playfair Display;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 7rem;
  max-width: 60%;
}
@media (max-width: 1100px) {
  #blogDetail .section_banner .title {
    color: var(--default-white, #FFF);
    /* title */
    font-family: Playfair Display;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 33px;
    /* 117.857% */
  }
}
#blogDetail .description {
  display: none;
}
#blogDetail .see-more-galley-images {
  display: none;
}
#blogDetail .content {
  margin-top: 90px;
}
#blogDetail .content img {
  width: 100%;
  height: auto;
}
#blogDetail .content p {
  color: var(--bodyText, #5A5A5A);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 187.5% */
}

#privacy {
  padding-top: 175px;
  background: #748173;
  padding-bottom: 100px;
}
#privacy h1 {
  font-size: 5rem;
  line-height: 5.5rem;
  color: #fff;
  font-weight: bold;
  font-family: "Playfair Display";
  text-align: left;
  margin-bottom: 30px;
}
#privacy h2 {
  font-size: 3rem;
  line-height: 3.5rem;
  color: #fff;
  font-weight: bold;
  font-family: "Playfair Display";
  text-align: left;
  margin-bottom: 30px;
  margin-top: 50px;
}
#privacy p {
  font-size: 1.6rem;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  margin-bottom: 30px;
}
#privacy li {
  font-size: 1.6rem;
  line-height: 1.3;
  color: #fff;
  font-weight: 600;
  font-family: "Montserrat";
  text-align: left;
  margin-bottom: 15px;
}