.section_cta {
    padding: 170px 0px;
    display: flex;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &::after{
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        mix-blend-mode: multiply;
        background: $dark;
        opacity: .5;
    }
    @media (max-width: 1100px) {
        padding: 90px 0px;
    }
    &--container {
        margin: 0 auto;
        display: grid;
        grid-template-columns: auto 1fr;
        width: 80%;
        max-width: 1297px;
        gap: 130px;
        align-items: center;
        position: relative;
        z-index: 9;
        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            padding-inline: 15px;
            gap: 21px;
        }
    }

    .title {
        @include fontTemplate(4rem, 1.1, #fff, $regular, $play);

        strong {
            font-family: $better;
            font-weight: 500;
            line-height: 1.2;
            font-size: 7rem;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 60%;
        @include fontTemplate(1.6rem, 1.9, #fff, $regular, $mont);
    }

    .link,
    .actions {
        padding: 20px 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include fontTemplate(
            1.6rem,
            1.1,
            #748173,
            $regular,
            $play,
            center
        );
        margin-top: 35px;
        width: fit-content;
        background-color: white;
        text-decoration: none;
        transition: .3s all ease-out;
        &:hover{
            background-color: $green;
            color: $white;
        }
    }
}
