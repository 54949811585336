#page404{
  .title{
    text-align: center;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    .sad{
      transform: rotate(90deg);
      font-size: 60px;
    }
  }
}