.success-message{
    padding: 10px 15px;
    background-color: $green-success;
    box-shadow: 0px 1px 5px black;
    position: fixed;
    right: 30px;
    bottom: 20px;
    color: white;
    z-index: 100;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 95%;
    align-items: center;
    display: none;
}