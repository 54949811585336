// body{
//     background-image: url(../img/parallel/EventosCorporativos.png);
//     background-size: 100%;
//     background-repeat: no-repeat;
// }

// #page_events-corporate{
//     opacity: 0.3;
// }
#page_events-corporate{
    .section_banner{
        .title{
            @media (max-width: 1100px){
                color: var(--default-white, #FFF);
                /* title */
                font-family: Playfair Display;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px; /* 117.857% */
                strong{
                    color: var(--default-white, #FFF);
                    font-family: Betterlett;
                    font-size: 63px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 60px; /* 95.238% */
                }
            }
        }
    }
}
