.kitchen{
    padding-bottom: 10rem;
    padding-top: 11.7rem;
    position: relative;
    height: 690px;
    @media (max-width: 1100px){
        padding-top: 6rem;
        height: auto;
        padding-bottom: 6rem;
    }
    &::after{
        content: '';
        top: 0;
        right: 0;
        position: absolute;
        background: $green;
        width: 65%;
        height: 100%;
        z-index: -1;
        @media (max-width: 1100px){
            width: 100%;
        }
    }
    .container{
        display: flex;
        position: relative;
        max-width: 1280px !important;
    }
    .content-image{
        aspect-ratio: 1.15;
        left: -180px;
        top: -45px;
        width: 841px;
        @media (max-width: 1100px){
            display: none;
        }
        img{
            transition: all .3s ease-out !important;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .content{
        width: 400px;
        position: relative;
        display: flex;
        flex-direction: column;
        transform: translate(-80px, 0px);
        @media (max-width: 1100px){
            width: 100%;
            padding-inline: 15px;
            transform: translate(0px, 0px);
        }
    }
    .title{
        @include  fontTemplate(4rem, 4.5rem, #fff, $regular, $play, left);
        strong{
            font-family: $better;
            font-weight: 100;
            font-size: 7.5rem;
            line-height: 7.5rem;
        }
    }
    .text{
        @include  fontTemplate(1.6rem, 3rem, #fff, $semi-bold, $mont, left);
        margin-top: 3.5rem;
        max-width: 350px;
        @media (max-width: 1500px){
            max-width: 300px;
        }
    }
    .cta{
        @include  fontTemplate(1.6rem, 1, #fff, $semi-bold, $mont, left);
        text-decoration: none;
        padding: 2.5rem 8.8rem;
        border-radius: 3.4rem;
        border: 1px solid #fff;
        margin-top: 5rem;
        width: fit-content;
        transition: all .3s ease;
        &:hover{
            background: #fff;
            color: $green;
        }
    }
}
