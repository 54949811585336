.blogNews{
    position: relative;
    padding-top: 7.5rem;
    .swiper{
        width: 100%;
        .swiper-button-prev, .swiper-rtl .swiper-button-next{
            background: var(--foundation-primary-dark, #576156);
            width: 25px;
            height: 25px;
            left: 0;
            &::after{
                font-size: 15px;
                font-weight: bold;
                color: #fff;
            }
        }
        .swiper-button-next, .swiper-rtl .swiper-button-prev{
            right: 0;
            background: var(--foundation-primary-dark, #576156);
            width: 25px;
            height: 25px;
            &::after{
                font-size: 15px;
                font-weight: bold;
                color: #fff;
            }
        }
    }
    .date{
        display: none;
        @media (max-width: 1100px){
            display: block;
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 21px; /* 175% */
        }
    }
    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 460px;
        background: var(--foundation-primary-dark, #576156);
        z-index: -1;
    }
    .container{
        display: flex;
        flex-direction: column;
        gap: 6.2rem;
    }
    .texts{
        display: grid;
        grid-template-columns: 1fr 2fr;
        @media (max-width: 1100px) {
            grid-template-columns: 1fr;
            row-gap: 42px;
        }
        h2{
            @include  fontTemplate(4rem, 4.5rem, #fff, $regular, $play, left);
            strong{
                font-family: $better;
                font-weight: 100;
                font-size: 5.5rem;
            }
        }
        p{
            @include  fontTemplate(1.55rem, 3.5rem, #fff, $medium, $mont, left);
        }
    }
    .cards{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.3rem;
        align-items: start;

        @media (max-width: 1499px) {
            gap: 40px !important;
        }
        @media (max-width: 1100px) {
            align-items: start;
            width: 100%;
            overflow: scroll;
            display: flex;
            gap: 1.6rem !important;
        }
    }
    .card{
        border: none;
        border-radius: 0;
        min-width: inherit;
        background: transparent;
        transition: all .1s ease;
        @media (max-width: 1100px) {
            min-width: 200px;
            width: 80%;
            margin: 0 auto;
        }
        &:hover{
            .card--img{
                transform: scale(1.2);
            }
        }
        .content-image{
            aspect-ratio: 0.85;

            @media (max-width: 1499px) {
                aspect-ratio: 1 !important;
            }
        }
        &--img{
            width: 100%;
            height: 490px;
            transition: all .3s ease !important;
            @media (max-width: 1100px) {
                height: 253px;
            }
        }
        &__content{
            padding: 2.2rem 2.5rem 2.9rem 3rem;
            width:calc(100% - 2.8rem);
            margin: 0 auto;
            background: #fff;
            position: relative;
            top: -60px;
            border-bottom: 6px solid $green;
            display: flex;
            flex-direction: column;
            &--title{
                @include  fontTemplate(2.2rem, 1, $dark, $regular, $play, left);
            }
            &--text{
                @include  fontTemplate(1.55rem, 3rem, $green, $medium, $mont, left);
                margin-top: 2rem;
                @media (max-width: 1100px) {
                    display: none;
                }
            }
            &--cta{
                padding: 1.7rem 5rem;
                border-radius: 3.4rem;
                border: 1px solid $green;
                @include  fontTemplate(1.6rem, 1, $green, $semi-bold, $mont, left);
                width: fit-content;
                margin-top: 3rem;
                text-decoration: none;
                transition: all .3s ease;
                z-index: 9;
                @media (max-width: 1100px) {
                    display: none;
                }
                &:hover{
                    background: $green;
                    color: #fff;
                }
            }
        }
    }
}
