.newsletter{
    min-height: 367px;
    position: relative;
    display: flex;
    align-items: center;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: #748173;
        opacity: .8;
        mix-blend-mode: multiply;
    }
    .bg-img{
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: -2;
    }
    .title{
        color: #FFF;
        font-family: Playfair Display;
        font-size: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 4.5rem; /* 112.5% */
        position: relative;
        z-index: 9;
        strong{
            color: #FFF;
            font-family: Betterlett;
            font-size: 6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 7rem; /* 116.667% */
        }
    }
    .box-send{
        position: relative;
        z-index: 9;
        @media (max-width: 1100px){
            margin-top: 60px;
        }
    }
    #newsletter-input{
        width: 100%;
        border: none;
        height: 70px;
        border-radius: 34px;
        opacity: 0.7;
        background: var(--secondary, #F8F7F7);
        padding-left: 50px;
        font-size: 1.6rem;
        color: var(--bodyText, #5A5A5A);
        @media (max-width: 1100px){
            height: 50px;
        }
        &::placeholder{
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 200% */
        }
    }
    .btn-send{
        position: absolute;
        right: 30px;
        top: 5px;
        @media (max-width: 1100px){
            right: 0;
            top: -5px;
            transform: scale(.8);
        }
    }
}
