.testimony{
    height: auto;
    position: relative;
    background: url('../img/depo/bg-depo.png') no-repeat;
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #748173 0% 0% no-repeat padding-box;
        mix-blend-mode: multiply;
        opacity: .8;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .swiper{
        .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            background: #fff;
            opacity: 1;
            border: 1px solid #fff;
        }
        .swiper-pagination-bullet-active{
            background: transparent;
        }
        .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
            bottom: 0%;
        }
    }
    .container{
        min-height: 100dvh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 9;
        padding: 50px 0;
    }
    .content{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5rem;
        .stars{
            height: 1.7rem;
        }
        .title{
            @include  fontTemplate(3rem, 4rem, #fff, $regular, $play, center);
            @media (max-width: 1100px){
                font-size: 2rem;
                line-height: 3rem;
            }
        }
        .name{
            @include  fontTemplate(1.8rem, 1, #fff, $semi-bold, $mont, left);
            padding-bottom: 50px;
        }
    }
}
