#back-to-top {
    margin: 70px auto 100px;
    left: 50%;
    bottom: 40px;
    transform: translateX(-50%);
    width: 70px;
    aspect-ratio: 1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #748173;
    transition: 0.4s;

    @media (max-width: 1100px) {
        margin: 60px auto;
        transform: translateX(0);
        width: 55px;
        margin-top: 0;
    }
    ion-icon {
        font-size: 40px;
        color: #748173;
        transition: 0.4s;
    }


    &:hover{
        border: 1px solid #5D675C;
        background-color: #5D675C;

        ion-icon{
            color: white;
        }
    }

    &:focus{
        background-color: #464D45;
        border: 1px solid #464D45;

        ion-icon{
            color: white;
        }
    }
}
