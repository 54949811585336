.fixed_icons{
    position: absolute;
    top: 33%;
    right: 7%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 99;
    @media (max-width: 1100px) {
        display: none;
    }
    a{
        width: 33px;
        height: 33px;
    }
}
.fb-icon{
    #path-1, #path-2, #path-3{
        transition: all 0.3s ease-out;
    }
    &:hover{
        #path-1{
            fill: $green;
        }
        #path-2{
            fill: #fff;
        }
        #path-3{
            fill:$green;
        }
    }
}
.insta-icon{
    .path-1, .path-2{
        transition: all 0.3s ease-out;
    }
    &:hover{
        .path-1{
            fill: $green;
        }
        .path-2{
            fill: #fff;
        }
    }
}
.linkedin-icon{
    .path-1, .path-2{
        transition: all 0.3s ease-out;
    }
    &:hover{
        .path-1{
            fill: $green;
        }
        .path-2{
            fill: #fff;
        }
    }
}
.wpp-icon{
    .path-1, .path-2{
        transition: all 0.3s ease-out;
    }
    &:hover{
        .path-1{
            fill: $green;
        }
        .path-2{
            fill: #fff;
        }
    }
}
