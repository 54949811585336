.instagram{
    .container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    h2{
        @include  fontTemplate(4rem, 1, $dark, $regular, $play, left);
        text-align: center;
        line-height: 6.5rem;
        margin-bottom: 10rem;
        strong{
            font-family: $better;
            color: $green;
            font-size: 7.3rem;
            font-weight: 100;
        }
    }
}
