#header {
    position: absolute;
    top: 0;
    height: 110px;
    z-index: 999;
    width: 100%;

}

.sub-links {
    display: none;
    position: absolute;
    width: 200%;
    top: 22px;
    padding: 12px 0;
    color: var(--default-white, #FFF);
    text-align: center;
    font-family: Montserrat;
    font-size: 15.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    border-bottom: solid 1px #ffffff;
    background: #748173;
    padding-left: 1.5rem;

    a {
        text-decoration: none;
        @include fontTemplate(1.55rem, 1, #fff, $medium, $mont, left);
        &:hover{
            font-weight: bold;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        li {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: start;
        }
    }
}

.ambiente-link {
    cursor: pointer;

    // &:hover {
    //     .sub-links {
    //         display: block;
    //     }
    // }
}
.sub-links{
    &.active{
        display: block;
    }
}

.wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 5rem;
    align-items: center;

    @media (max-width: 1100px) {
        padding-inline: 9px;
    }

    .logo {
        max-width: 177px;
        @media (max-width: 1300px) {
            max-width: 150px;
        }
    }

    .links {
        display: flex;
        gap: 3.4rem;
        @media (max-width: 1300px) {
            gap: 2rem;
        }
        @media (max-width: 1100px) {
            display: none;
        }
    }

    .link {
        text-decoration: none;
        @include fontTemplate(1.55rem, 1, #fff, $medium, $mont, left);
        position: relative;

        span {
            color: $green;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            width: 0%;
            bottom: -4px;
            height: 2px;
            background: $green;
            transition: all .2s ease;
        }

        &:hover {
            &::before {
                width: 100%;
            }
        }

        &.active {
            &::before {
                width: 100%;
            }
        }
    }

    .search {
        cursor: pointer;
    }
}

#bg-menu-mobile {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: -100%;
    background: var(--default-black, #222);
    mix-blend-mode: multiply;
    will-change: transform;


    ul li {
        font-weight: 300;
        font-size: 30px;
        position: relative;
        margin-bottom: 30px;
        left: 100%;
        cursor: pointer;
        will-change: transform;

    }

    ul li:hover {
        color: #111;
        -webkit-transition: all .1s ease-in-out;
        -moz-transition: all .1s ease-in-out;
        transition: all .1s ease-in-out;
    }
}
.sub-menu-mobile{
    padding-top: 3rem;
}
.menu-mobile-links {
    width: 100%;
    position: fixed;
    top: 100px;
    left: 0;
    list-style: none;
    color: #fff;
    z-index: 3;
    pointer-events: none;
    height: 80dvh;
    overflow-x: hidden;
    overflow-y: hidden;
    &.active{
        pointer-events: auto;
        overflow-y: scroll;
    }
    li {
        position: relative;
        padding-bottom: 14px;
        margin-bottom: 14px;
        left: 100%;
        cursor: pointer;
        will-change: transform;
        color: var(--default-white, #FFF);
        text-align: start;
        text-shadow: 0px 3px 8px rgba(0, 0, 0, 0.25);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        /* 166.667% */
        padding-left: 15px;
        a{
            text-decoration: none;
        }
        &::after {
            content: '';
            position: absolute;
            left: 15px;
            bottom: 0;
            width: 80%;
            height: 2px;
            background: #fff;
            opacity: .1;
        }
    }
}
.ambiete-mobile{
    ul{
        display: none;
    }
    .active{
        display: block;
    }
}
/* hang burger */

#hamburger {
    width: 30px;
    height: 30px;
    position: relative;
    cursor: pointer;
    position: absolute;
    top: 45px;
    right: 15px;
    background-color: transparent;
    display: none;

    @media (max-width: 1100px) {
        display: block;
    }
}

//   #hamburger:hover span:nth-child(1) {
//     top: -4px;
//     -webkit-transition: .20s ease-in-out;
//     -moz-transition: .20s ease-in-out;
//     -o-transition: .20s ease-in-out;
//     transition: .20s ease-in-out;
//   }

//   #hamburger:hover span:nth-child(3) {
//     top: 34px;
//     -webkit-transition: .16s ease-in-out;
//     -moz-transition: .16s ease-in-out;
//     -o-transition: .16s ease-in-out;
//     transition: .16s ease-in-out;
//   }

#hamburger span {
    z-index: 3;
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: #fff;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .20s ease-in-out;
    -moz-transition: .20s ease-in-out;
    -o-transition: .20s ease-in-out;
    transition: .25s ease-in-out;
}

#hamburger span:nth-child(1) {
    top: 0px;
}

#hamburger span:nth-child(2) {
    top: 10px;
}

#hamburger span:nth-child(3) {
    top: 20px;
}

#hamburger.open {
    .menu-mobile-links {
        pointer-events: all;
    }
}

#hamburger.open span:nth-child(1) {
    top: 14px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    background-color: #fff;
}

#hamburger.open span:nth-child(2) {
    opacity: 0;
    left: -30px;
    -webkit-transition: .16s ease-in-out;
    -moz-transition: .16s ease-in-out;
    -o-transition: .16s ease-in-out;
    transition: .16s ease-in-out;
}

#hamburger.open span:nth-child(3) {
    top: 14px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    background-color: #fff;
}
