.destaque{
    margin-top: 120px;
    .swiper-button {
        &-prev,
        &-next {
            &::after,
            &::before {
                display: none;
            }

            width: 50px !important;
            height: 50px !important;
            aspect-ratio: 1 !important;
            border-radius: 50%;
            background-color: #464d4542;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            padding: 0 !important;
            transition: all .3s ease-out;
            ion-icon {
                font-size: 30px;
                color: white;
            }
            &:hover{
                background: $green;
            }
        }
    }
    .swiper-container{
        position: relative;
        margin: 0 auto;
    }
    .swiper-container .swiper-button-prev{
        left: -73px;
        @media (max-width: 1100px){
            display: none;
        }
    }
    .swiper-container .swiper-button-next{
        right: -73px;
        @media (max-width: 1100px){
            display: none;
        }
    }
    h2{
        margin-bottom: 26px;
        strong{
            @include fontTemplate(4rem, 1, $green, $medium, $better, center);
            letter-spacing: -1.6px;
        }
    }
    .cardsExpirence{
        margin-top: 20px;
        @media (max-width: 1100px){
            margin-top: 20px;
        }
        .box-cards{
            @media (max-width: 1100px){
                width: 100%;
                .row{
                    flex-wrap: nowrap;
                }
            }
        }

        .card-expirence{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            .wrapper-img{
                width: 100%;
                height: 390px;
                overflow: hidden;
                @media (max-width: 1100px){
                    height: 364px;
                }
            }
            img{
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-out;
                object-fit: cover;
            }

            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            .body{
                background: #fff;
                padding: 20px 16px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: calc(100% - 30px);
                top: -122px;
                position: relative;
                background: var(--default-white, #FFF);
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
                border-bottom: 6px solid $green;
                gap: 11px;
                @media (max-width: 1100px){
                    padding: 30px 21px;
                    margin-bottom: -62px;
                }

                p{
                    color: var(--default-black, #222);
                    font-family: Playfair Display;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 3rem; /* 187.5% */
                }
                .date{
                    color: var(--bodyText, #5A5A5A);
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 30px; /* 187.5% */
                }
            }
        }
    }
}
