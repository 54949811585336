#experience{
    .lupa{
        position: absolute;
        top: 30px;
        right: 30px;
        opacity: 0;
        z-index: 9;
    }
    .section_banner .title{
        font-size: 5.3rem;
        line-height: 5.6rem;
        strong{
            font-size: 7.7rem;
            line-height: normal;
        }
        @media (max-width: 1100px){
            max-width: 63%;
            font-size: 2.8rem;
            line-height: 33px;
            strong{
                font-size: 5.4rem;
            }
        }
    }
    .description{
        display: none;
    }
    .see-more-galley-images{
        display: none;
    }
    .section_banner .title{
        align-self: flex-end;
    }
    .fixed_icons{
        right: inherit;
        left: 0;
        top: 30vh;
        @media (max-width: 1100px){
            left : 22px;
            top: 22vh;
        }
    }
    .memory{
        margin-top: 12rem;
        margin-bottom: 12rem;
        @media (max-width: 1100px){
            margin-top: 6rem;
            margin-bottom: 6rem;
        }
        .title{
            color: var(--default-black, #222);
            font-family: Playfair Display;
            font-size: 4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4.5rem; /* 112.5% */
            @media (max-width: 1100px){
                font-size: 2.8rem;
                line-height: 3.3rem;
                max-width: 85%;
                br{
                    display: none;
                }
            }
            strong{
                color: var(--foundation-primary-normal, #748173);
                font-family: Betterlett;
                font-size: 9rem;
                font-style: normal;
                font-weight: 400;
                line-height: 10rem; /* 111.111% */
                @media (max-width: 1100px){
                    font-size: 6.5rem;
                    line-height: 6rem;
                    display: block;
                }
            }
        }
        .text{
            margin-top: 43px;
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 500;
            line-height: 3rem; /* 200% */
        }
    }
    .mission{
        position: relative;
        .mission-img{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            aspect-ratio: 1.98;
            width: 55%;
            height: 390px;
            img{
                transition: all .3s ease-out;
            }
            &:hover{
                svg{
                    opacity: 1;
                }
                img{
                    transform: scale(1.1);
                }
            }
            @media (max-width: 1100px){
                display: none;
            }
        }
        .mobile-only{
            display: none;
            @media (max-width: 1100px){
                display: block;
                z-index: 999;
                position: relative;
            }
        }
        .box-1{
            margin-top: 120px;
            @media (max-width: 1100px){
                margin-top: 60px;
                margin-bottom: 34px;
            }
            &::after{
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 390px;
                width: 50%;
                background: var(--foundation-primary-normal, #748173);
                z-index: 2;
                @media (max-width: 1100px){
                    width: 100%;
                    height: 253px;
                }
            }
            h2{
                color: var(--default-white, #FFF);
                /* title */
                font-family: Playfair Display;
                font-size: 4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 4.5rem; /* 112.5% */
                position: relative;
                z-index: 3;

            }
            p{
                color: var(--default-white, #FFF);
                font-family: Montserrat;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 3rem; /* 200% */
                position: relative;
                z-index: 3;
                strong{
                    color: var(--default-white, #FFF);
                    font-family: Betterlett;
                    font-size: 6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 7rem; /* 116.667% */
                    @media (max-width: 1100px){
                        line-height: 4rem;
                    }
                }
            }
        }
        .box-2{
            margin-top: 240px;
            @media (max-width: 1100px){
                margin-top: 60px;
            }
            h2{
                color: var(--default-black, #222);
                /* title */
                font-family: Playfair Display;
                font-size: 4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 4.5rem; /* 112.5% */
            }
            p{
                color: var(--bodyText, #5A5A5A);
                font-family: Montserrat;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 3rem; /* 200% */
                @media (max-width: 1100px){
                    margin-top: 16px;
                }
            }
        }
    }
    .cardsExpirence{
        margin-top: 120px;
        @media (max-width: 1100px){
            margin-top: 60px;
        }
        .box-cards{
            @media (max-width: 1100px){
                width: 100%;
                overflow-x: scroll;
                .row{
                    flex-wrap: nowrap;
                }
            }
        }

        .card-expirence{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            .wrapper-img{
                width: 100%;
                height: 390px;
                overflow: hidden;
                @media (max-width: 1100px){
                    height: 364px;
                }
            }
            img{
                width: 100%;
                height: 100%;
                transition: all 0.3s ease-out;
                object-fit: cover;
            }

            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            .body{
                background: #fff;
                padding: 30px 60px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: calc(100% - 30px);
                top: -122px;
                position: relative;
                background: var(--default-white, #FFF);
                box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.25);
                border-bottom: 6px solid $green;
                @media (max-width: 1100px){
                    padding: 30px 21px;
                    margin-bottom: -62px;
                }
                h2{
                    color: var(--default-black, #222);
                    font-family: Playfair Display;
                    font-size: 2.2rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 3rem; /* 136.364% */
                    margin-top: 16px;
                }
                p{
                    color: var(--bodyText, #5A5A5A);
                    font-family: Montserrat;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 3rem; /* 187.5% */
                }
                a{
                    text-decoration: none;
                    color: var(--foundation-primary-normal, #748173);
                    text-align: center;
                    font-family: Playfair Display;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 5.5rem; /* 343.75% */
                    letter-spacing: -0.64px;
                    display: inline-flex;
                    padding: 0px 50px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 34px;
                    border: 1px solid var(--foundation-primary-normal, #748173);
                    margin-top: 30px;
                    transition: all .3s ease-out;
                    &:hover{
                        background: var(--foundation-primary-normal, #748173);
                        color: #fff;
                    }
                }
            }
        }
    }
    .drink,.wine{
        padding-top: 120px;
        background: var(--foundation-primary-normal, #748173);
        padding-bottom: 100px;
        @media (max-width: 1100px){
            margin-top: 0;
            padding-top: 84px;
            padding-bottom: 84px;
        }
        .drinkSwiper, .coffeSwiper{
            width: 100%;
            min-height: 588px;
            @media (max-width: 1100px){
                min-height: 510px;
            }
            .swiper-wrapper{
                padding-bottom: 60px;
                @media (max-width: 1100px){
                    padding-bottom: 0;
                }
            }
            .content-image{
                aspect-ratio: 0.81;
                &:hover{
                    .lupa{
                        opacity: 1;
                    }
                    img{
                        transform: scale(1.1);
                    }
                }
            }
            img{
                object-fit: cover;
                width: 100%;
                transition: all .3s ease-out;
            }
            .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
                bottom: var(--swiper-pagination-bottom,16px);
            }
            .swiper-pagination-bullet{
                background: #fff;
                outline: 1px solid #fff;
                opacity: 1;
            }
            .swiper-pagination-bullet-active{
                background: transparent;
                outline: 1px solid #fff;
            }
        }
        .content{
            .title{
            color: var(--default-white, #FFF);
            /* title */
            font-family: Playfair Display;
            font-size: 4rem;
            font-style: normal;
            font-weight: 400;
            line-height: 4.5rem; /* 112.5% */
            }
            .subtitle{
                color: var(--default-white, #FFF);
                font-family: Montserrat;
                font-size: 2.2rem;
                font-style: normal;
                font-weight: 500;
                line-height: 3rem; /* 136.364% */
                strong{
                    color: var(--default-white, #FFF);
                    font-family: Betterlett;
                    font-size: 4rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 5rem; /* 125% */
                }
            }
            .text{
                color: var(--default-white, #FFF);
                font-family: Montserrat;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 500;
                line-height: 3rem; /* 200% */
                margin-top: 60px;
            }

        }
    }
  .wine{
    background: #fff;
    .text{
      color: var(--bodyText, #5A5A5A) !important;
    }
    .subtitle{
      color: var(--bodyText, #5A5A5A) !important;
    }
    .title{
      color: var(--bodyText, #5A5A5A) !important;
    }
  }
}
