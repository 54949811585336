.termsfeed-com---nb-simple{
    right: 25vw;
    border: 2px solid #748173;
    @media (max-width: 799px){
        right: 10vw;
    }
}
.termsfeed-com---nb .cc-nb-title{
    @include  fontTemplate(2.4rem, 1, #222222, bold, $play, left);
}
.termsfeed-com---nb .cc-nb-text{
    @include  fontTemplate(1.6rem, 1.2, #222222, normal, $mont, left);
}
.termsfeed-com---palette-light .cc-nb-okagree,.termsfeed-com---palette-light .cc-nb-reject{
    color: #748173;
    border: 1px solid #748173;
    border-radius: 32px;
    background: #fff;
    transition: all .3s ease;
    &:hover{
        background: #748173;
        color: #fff;
    }
}
