#footer{
    padding-top: 112px;
    background: $white;
    @media (max-width: 1100px) {
        padding-top: 0;
    }
    .footer-bottom{
        border-top: 1px solid $green;
        padding: 65px 0px;
        margin-top: 135px;
        @media (max-width: 1100px) {
            margin-top: 60px;
         }
        &--copyright{
            @include  fontTemplate(1.55rem, 1, $grey, $regular, $mont, left)
        }
    }
    .container-bottom{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    .wrapper{
        display: grid;
        grid-template-columns: 225px repeat(4, 200px) ;
        justify-content: space-between;
        align-items: start;
        @media (max-width: 1100px) {
            padding-top: 42px;
            padding-inline: 9px;
            grid-template-columns: 1fr 1fr;
            row-gap: 60px;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        @media (max-width: 1100px) {
           &:first-child{
                grid-column: span 2;
           }
        }
        .logo{
            max-width: 165px;
            margin-bottom: 20px;
        }
        h2{
            margin-bottom: 4.4rem;
            @include  fontTemplate(2rem, 1, $green, $medium, $play, left);
        }
        a{
            @include  fontTemplate(1.55rem, 1, $grey, $regular, $mont, left);
            text-decoration: none;
            margin-bottom: 1.5rem;
            transition: all .2s ease;
            display: flex;
            align-items: center;
            gap: 5px;
            @media (max-width: 1100px) {
                font-size: 1.4rem;
            }
            &:hover{
                color: $green;
            }
        }
        p{
            @include  fontTemplate(1.75rem, 3.2rem, $grey, $regular, $mont, left);
        }
        .location{
            line-height: 3.2rem;
        }
    }

}
