$red-error: #95201C;
$green-success: #2C4325;
$gray200: #0000002B;
$gray300: #00000029;
$gray400: #7C7C7C;
$gray500: #707070;

$black500: #111215;
$black900: #000;

$white: #ffffff;

$blue300: #6DC7FF;
$blue500: #13B9EF;

$pink400: #D76FA2;
$pink600: #E548AD;
/*  */

/*------------- Hover background gradient --------------*/

@property --myColor1 {
    syntax: '<color>';
    initial-value: #FCA32D;
    inherits: false;
}

@property --myColor2 {
    syntax: '<color>';
    initial-value: #FF6196;
    inherits: false;
}

.button-hover-linearGradient {
    background-image: linear-gradient(
        105deg,
        var(--myColor1) 7%,
        var(--myColor2) 78%
    );
    transition: --myColor1 0.7s, --myColor2 0.7s;
    &:hover {
        --myColor1: #ff933c;
        --myColor2: #ff933c;
    }
}

.button-hover-linearGradient-others-browsers {
    background-image: linear-gradient(
        105deg,
        #ff933c 7%,
        #ff6196 78%
    );
    background-image: -o-linear-gradient(
        105deg,
        #ff933c 7%,
        #ff6196 78%
    );
    background-image: -moz-linear-gradient(
        105deg,
        #ff933c 7%,
        #ff6196 78%
    );
}


$green : #748173;
$grey : #5A5A5A;
$dark : #222222;
$white : #F8F7F7;


:root{
    --foundation-primary-normal: #748173;
    --default-black: #222;
    --bodyText: #5A5A5A;
}
