.space{
    margin-bottom: 16.9rem;
    margin-top: 5rem;
    @media (max-width: 1100px){
        margin-bottom: 50px;
    }
    .container{
        display: flex;
        justify-content: center;
        gap: 80px;
        @media (max-width: 1100px){
            flex-direction: column;
            gap: 4rem;
            padding-inline: 15px;
        }
    }
    .content-image{
        width: 100%;
        height: 427px;
        @media (max-width: 1100px){
            height: 300px;
        }
        img{
            transition: all .3s ease-out !important;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        max-width: 541px;
        height: 838px;
        justify-content: space-between;
        @media (max-width: 1300px){
            max-width: 450px;
        }
        @media (max-width: 1100px){
            height: auto;
            gap: 2rem;
        }
        .title{
            @include  fontTemplate(4rem, 4.5rem, $dark, $regular, $play, left);
            strong{
                font-weight: 100;
                font-family: $better;
                color: $green;
                font-size: 7.3rem;
                line-height: 7.3rem;
            }
        }
        .text{
            @include  fontTemplate(1.55rem, 3rem, $grey, $medium, $mont, left);

        }
        .cta{
            @include  fontTemplate(1.6rem, 1, $green, $semi-bold, $mont, left);
            border-radius: 3.4rem;
            border: 1px solid $green;
            padding: 2.5rem 5.8rem;
            width: fit-content;
            text-decoration: none;
            transition: all .3s ease;
            &:hover{
                background: $green;
                color: #fff;
            }
        }
        &--left{
            .content-imag{
                order: 3;
            }
            .double {
                display: grid;
                aspect-ratio: 0.88;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                counter-increment: section;
            }

            .double__img {
                grid-area: 1 / 1 / -1 / -1;
                background-size: cover;
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
        &--right{
            .double {
                display: grid;
                aspect-ratio: 0.85;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                counter-increment: section;
            }

            .double__img {
                grid-area: 1 / 1 / -1 / -1;
                background-size: cover;
                width: 100%;
                height: 100%;
                position: relative;
            }
        }
    }

}
