#blogDetail{
    .fixed_icons{
        @media (max-width: 1100px){
            top: 23%;
        }
    }
    .blog-detail-content{
        margin-top: 30px;
        span{
            color: var(--default-white, #FFF);
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 140% */
            @media (max-width: 1100px){
                color: var(--default-white, #FFF);
                font-family: Montserrat;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px; /* 200% */
            }
        }
    }
    .section_banner{
        background-image: none !important;
        background-color: var(--foundation-primary-normal, #748173);
        &::after{
            display: none;
        }
        .title{
            color: var(--default-white, #FFF);
            font-family: Playfair Display;
            font-size: 6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 7rem;
            max-width: 60%;
            @media (max-width: 1100px){
                color: var(--default-white, #FFF);
                /* title */
                font-family: Playfair Display;
                font-size: 28px;
                font-style: normal;
                font-weight: 400;
                line-height: 33px; /* 117.857% */
            }
        }
    }
    .description{
        display: none;
    }
    .see-more-galley-images{
        display: none;
    }
    .content{
        margin-top: 90px;
        img{
            width: 100%;
            height: auto;
        }
        p{
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 187.5% */
        }
    }
}
