#blog{
    .destaque{
        display: none;
        @media (max-width: 1100px){
            margin-top: -70px;
            margin-bottom: 50px;
            display: block;
            h2{
                display: none;
            }
        }
    }
    .section_banner .title{
        color: var(--default-white, #FFF);
        font-family: Playfair Display;
        font-size: 7rem;
        font-style: normal;
        font-weight: 400;
        line-height: 8rem; /* 114.286% */
        strong{
            color: var(--default-white, #FFF);
            font-family: Betterlett;
            font-size: 11rem;
            font-style: normal;
            font-weight: 400;
            line-height: 10rem; /* 90.909% */
        }
        @media (max-width: 1100px){
            color: var(--default-white, #FFF);
            font-family: Playfair Display;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: 40px; /* 111.111% */
            max-width: 80%;
            strong{
                color: var(--default-white, #FFF);
                font-family: Betterlett;
                font-size: 63px;
                font-style: normal;
                font-weight: 400;
                line-height: 60px; /* 95.238% */
            }
        }
    }
    .description{
        display: none;
    }
    .see-more-galley-images{
        display: none;
    }
    .container{
        max-width: 1196px;
    }
    .title-filter{
        color: #464D45;
        font-family: 'Playfair Display';
        font-size: 4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 8rem; /* 114.286% */
        margin-bottom: 50px;
    }
    .filter{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        margin-bottom: 80px;
        @media (max-width: 1100px){
            grid-template-columns: 100px 100px 1fr;
            display: grid;
            gap: 10px;
            margin-bottom: 100px;
        }
        .filter-item{
            display: flex;
            padding: 0px 26px;
            justify-content: center;
            align-items: center;
            border-radius: 34px;
            background: var(--foundation-primary-dark-hover, #fff);
            color: var(--default-white, $green);
            text-align: center;
            font-family: Playfair Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 55px; /* 343.75% */
            letter-spacing: -0.64px;
            text-decoration: none;
            border: 1px solid $green;
            &.active{
                background: var(--foundation-primary-dark-hover, #464D45);
                color: var(--default-white, #FFF);
            }
            &:nth-child(4){
                grid-column: span 2;
            }
            &:nth-child(5){
                grid-column: span 1;
            }
            &:nth-child(6){
                grid-column: span 3;
            }
        }
    }
    .post{
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        align-items: center;
        img{
            width: 100%;
            height: 490px;
            object-fit: cover;
            @media (max-width: 1100px){
                height: 410px;
            }
        }
        .body{
            width: calc(100% - 30px);
            background: #fff;
            padding: 30px;
            position: relative;
            border-bottom: 6px solid $green;
            top: -100px;
            margin-bottom: -65px;
        }
        .title{
            color: var(--default-black, #222);
            font-family: Playfair Display;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 136.364% */
        }
        .date{
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 187.5% */
            margin: 16px 0;
        }
        .text{
            overflow: hidden;
            color: var(--bodyText, #5A5A5A);
            text-overflow: ellipsis;
            whitespace: nowrap;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 187.5% */
        }
        .post-btn{
            display: flex;
            padding: 0px 50px;
            justify-content: flex-start;
            align-items: center;
            color: var(--foundation-primary-normal, #748173);
            text-align: center;
            font-family: Playfair Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 55px; /* 343.75% */
            letter-spacing: -0.64px;
            border-radius: 34px;
            border: 1px solid var(--foundation-primary-normal, #748173);
            width: fit-content;
            text-decoration: none;
            transition: .3s ease-out all;
            position: relative;
            z-index: 9;
            margin-top: 30px;
            &:hover{
                background: var(--foundation-primary-normal, #748173);
                color: #fff;
            }
        }
    }
    .side-post{
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 1100px){
            display: none;
        }
        img{
            height: 320px;
            object-fit: cover;
            width: 100%;

        }
        .body{
            width: calc(100% - 6px);
            background: #fff;
            padding: 20px 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 7px;
            border-bottom: 6px solid $green;
            top: -60px;
            position: relative;
            margin-bottom: -33px;
        }
        .title{
            color: var(--default-black, #222);
            font-family: Playfair Display;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px; /* 187.5% */
        }
        .date{
            color: var(--bodyText, #5A5A5A);
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px; /* 187.5% */
        }
    }
    .pagination{
        display: flex;
        gap: 16px;
        justify-content: center;
        align-items: center;
        padding-left: 38px;
        margin-top: 60px;
        margin-bottom: 120px;
        @media (max-width: 1100px){
            padding-left: 0;
            margin-top: 0;
        }
        a{
            color: var(--bodyText, #5A5A5A);
            font-family: Playfair Display;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px; /* 136.364% */
            letter-spacing: -0.88px;
            text-decoration: none;
            position: relative;
            &.active{
               &::after{
                   content: '';
                   position: absolute;
                   left: -3px;
                   bottom: -2px;
                   height: 2px;
                   width: 180%;
                   background: $green;
               }
            }
        }


    }
    .seta{
        margin-top: 60px;
        position: relative;
        svg{
            transition: .3s ease-out all;
            #circle{
                transition: .3s ease-out all;
            }
            #arrow{
                transition: .3s ease-out all;
            }
            &:hover{
                 #circle{
                    fill: #748173;
                 }
                 #arrow{
                    fill: #fff;
                 }
            }
        }
    }
    .seta-topo{
        @media (max-width: 1100px){
            transform: translate(37vw, -5vh);
        }
    }

}
   .section_valuate {
        background-size: cover;
        background-repeat: no-repeat;
        padding: 158px 0px 166px;
        display: flex;
        overflow: hidden;

        &--container {
            width: 80%;
            max-width: 1200px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr auto 1fr;
            gap: 17%;
        }

        .box_content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 30px;

            &-title {
                @include fontTemplate(
                    4rem,
                    1.1,
                    #fff,
                    $regular,
                    $play,
                    center
                );
                width: fit-content;
                strong {
                    font-family: $better;
                    line-height: 1.2;
                    font-size: 6.2rem;
                    font-weight: 500;
                }
            }
            &-icons{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 30px;

                a{
                    background-color: white;
                    width: 55px;
                    height: 55px;
                    aspect-ratio: 1;
                    border-radius: 50%;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: all .3s ease-out;
                    &:hover{
                        background-color: #748173;
                        ion-icon{
                            color: #fff;
                        }
                    }
                }

                ion-icon{
                    font-size: 3rem;
                    color: #748173;
                    transition: all .3s ease-out;

                }
            }
        }
        .diviser {
            height: 140%;
            transform: translateY(-15%);
            width: 2px;
            background-color: white;
        }
    }
