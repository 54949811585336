.section_banner{
    background-size: cover;
    background-repeat: no-repeat;
    height: auto;
    padding: 176px 0px 111px;
    display: flex;
    position: relative;
    &:after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.8;
        pointer-events: none;
    }
    &--container{
        width: 80%;
        max-width: 1196px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 9;
        @media (max-width: 1100px){
            width: 100%;
            padding-inline: 15px;
        }
    }

    .navigate{
        display: flex;
        align-items: center;
        gap: 21px;
        @media (max-width: 1100px){
            gap: 10px;
        }
        a{
            @include  fontTemplate(1.55rem, 1, #fff, $medium, $mont);
            text-decoration: none;
            @media (max-width: 1100px){
                line-height: 1.5;
            }
            &.active{
                text-underline-offset: 5px;
                text-decoration: underline;
            }
        }
    }

    .title{
        @include  fontTemplate(4rem, 1.1, #fff, $regular, $play);
        max-width: 440px;
        margin-top: 68px;
        line-height: 4.5rem; /* 112.5% */
        @media (max-width: 1100px){
            line-height: 40px;
        }
        strong{
            font-family: $better;
            font-weight: 500;
            line-height: 1.4;
            font-size: 7rem;
        }
    }

    .description{
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        max-width: 60%;
        background: #748173;
        padding: 1.5rem;
        @include fontTemplate(
            1.6rem,
            1.9,
            #fff,
            $regular,
            $mont
        );
        @media (max-width: 1100px){
            max-width: 100%;
        }
    }

    .group-button{
        display: flex;
        align-items: center;
        gap: 25px;
        @media (max-width: 1100px){
            align-items: flex-start;
            gap: 25px;
            flex-direction: column;
        }
    }

    .link, .actions{
        padding: 20px 50px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include  fontTemplate(1.6rem, 1.1, #748173, $regular, $play, center);
        margin-top: 35px;
        width: fit-content;
        background-color: white;
        text-decoration: none;
        display: flex;
        gap: 10px;
        transition: .3s ease-out all;
        svg{
            path{
                transition: .3s ease-out all;
            }
        }
        &:hover{
            background-color: $green;
            color: white;
            svg{
                path{
                    fill: white;
                }
            }
        }
    }

    .arrow-down{
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);

        width: 50px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: #d4d8d441;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;

        ion-icon{
            font-size: 30px;
            color: white;
        }
    }

    @media (max-width: 1499px) {
        padding: 130px 0px 100px;
    }
    @media (max-width: 1100px) {
        padding: 130px 0px 84px;
    }
}
