#page_ambience{
    .fixed_icons{
        @media (max-width: 1100px){
            display: none;
        }
    }
}
.ambience{
    margin-bottom: 14.5rem;
    margin-top: 30rem;
    @media (max-width: 1100px){
        margin-top: 60px;
        margin-bottom: 50px;
    }
    .container{
        display: flex;
        gap: 10rem;
        @media (max-width: 1100px){
            flex-direction: column;
            padding-inline: 15px;
            gap: 50px;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        gap: 3.5rem;
        max-width: 620px;
        .text{
            @include  fontTemplate(1.55rem, 3rem, $grey, $medium, $mont, left);
            margin-top: 1.6rem;
        }
    }
    .card{
        &--main, &--second, &--grid{
            .title{
                @include  fontTemplate(2.2rem, 1, $dark, $regular, $play, left);
                margin-top: 1.1rem;
            }
        }
        &--main{
            .content-image{
                aspect-ratio: 0.838;
                max-width: 550px;
                @media (max-width: 1100px){
                    aspect-ratio: 2.13;
                }
                img{
                    transition: all .3s ease-out !important;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
            .double{
                aspect-ratio: 0.838;
            }
            img{
                margin-top: 1.5rem;
                width: min(100%, 555px);
            }
        }
        &--second{
            .content-image, .double{
                aspect-ratio: 2.13;
                img{
                    transition: all .3s ease-out !important;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
        }
        &--grid{
            .content-image, .double{
                aspect-ratio: 1.6;
                img{
                    transition: all .3s ease-out !important;
                }
                &:hover{
                    img{
                        transform: scale(1.1);
                    }
                }
            }
            img{
                width: 100%;
                aspect-ratio: 16/10;
                object-fit: cover;
            }
        }
    }
    .grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 3rem;
        column-gap: 4rem;
    }
    .titleMain{
        @include  fontTemplate(4rem, 4.5rem, $dark, $regular, $play, left);
        max-width: 75%;
        strong{
            font-family: $better;
            color: $green;
            font-size: 5.5rem;
            font-weight: 100;
        }
    }
    .textMain{
        max-width: 75%;
    }
}
