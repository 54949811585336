#home{
    .fixed_icons{
        display: flex;
    }
}
.slider-home{
    height: 100vh;
    position: relative;
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 1500px){
      height: 250px;
  }
  @media (max-height: 650px){
        height: 200px;
  }
}
.mySwiper{
    .swiper-wrapper{
        align-items: center;
    }
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 100%;
  width: 100%;
  position: relative;
  .swiper-slide{
    position: relative;
    &::after{
        background: #585857 0 0 no-repeat padding-box;
        content: "";
        height: 100%;
        left: 0;
        mix-blend-mode: multiply;
        opacity: .5;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 9;
        }
  }
  .destaque-text{
    position: absolute;
    width: 100%;
    height: 200px;
    top: -10%;
    left: 0;
    z-index: 99;
    @media (max-width: 1100px){
        top: 8%;
    }
    span{
        font-size: 345px;
        color: #fff;
        opacity: .1;
        font-family: $play;
        @media (max-width: 1100px){
            font-size: 100px;
        }
    }
  }
  .content{
    display: flex;
    position: absolute;
    right:23%;
    top: 30%;
    flex-direction: column;
    z-index: 99;
    gap: 4.5rem;
    @media (max-width: 1100px){
        right: inherit;
        width: calc(100% - 30px);
        top: 33%;
    }
    .title{
        @include  fontTemplate(5rem, 5.5rem, #fff, $regular, $play, left);
        max-width: 500px;
        strong{
            color: #fff;
            font-family: $better;
            font-weight: 100;
            line-height: 7.5rem;
            font-size: 7.5rem;
            @media (max-width: 1100px){
                font-size: 4rem;
                line-height: 5rem;
            }
        }
        @media (max-width: 1100px){
            font-size: 3.5rem;
            line-height: 4rem;
        }
    }
    .cta{
        @include  fontTemplate(1.6rem, 1, #fff, $semi-bold, $mont, left);
        padding: 2.5rem 5rem;
        transition: all .3s ease;
        border-radius: 3.4rem;
        border: 1px solid #fff;
        width: fit-content;
        text-decoration: none;
        &:hover{
            background: $green;
            color: #fff;
        }
    }
  }
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 15vh;
  }

  .swiper-pagination-bullet{
    width: 15px;
    height: 15px;
    background: transparent;
    border: 1px solid #fff;
    opacity: 1;
  }
  .swiper-pagination-bullet-active{
    background: #748173;
    border-color: #748173;
  }
}

.mySwiper {
  box-sizing: border-box;
  padding: 10px 0;
  @media (max-width: 1100px) {
    left: 0;
    width: 100%;
    bottom: 0;
    padding-inline: 35px;
  }
  .swiper-button-prev, .swiper-rtl .swiper-button-next{
    background: var(--foundation-primary-dark, #576156);
    width: 25px;
    height: 25px;
    left: 5px;
    top: 58%;
    &::after{
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
    background: var(--foundation-primary-dark, #576156);
    width: 25px;
    height: 25px;
    right: 5px;
    top: 58%;
    &::after{
        font-size: 15px;
        font-weight: bold;
        color: #fff;
    }
}
  .wrapper-img-thumb{
    aspect-ratio: 1.83;
    overflow: hidden;
    img{
        transition: all .3s ease-out;
    }
    &:hover{
        img{
            transform: scale(1.1);
        }
    }
  }
  .center-text{
    display: flex;
    justify-content: center;
    &::after, &::before{
        position: absolute;
        content: '';
        left: 0;
        top: 7%;
        height: 1px;
        width: 45%;
        background: #fff;
        @media (max-width: 1100px) {
            width: 35%;
        }
    }
    &::before{
        right: 0;
        left: inherit;
    }
    span{
        @include  fontTemplate(2rem, 1, #fff, $semi-bold, $play, left);
        position: relative;
    }
  }
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 80%;
  opacity: 1;
  display: flex;
  flex-direction: column;
  background: transparent;
  align-items: flex-start;
  gap: 1.8rem;
  cursor: pointer;
  p{
    @include  fontTemplate(1.6rem, 1, #fff, $semi-bold, $mont, left)
  }
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
}
.slider-home{
    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $green;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    height: 100vh;
      .scroll {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 0.5);
        font-family: 'font-2';
        font-size: calc(0.5rem + 0.35vw);
        z-index: 10;
      }
      .slider {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .slider__text {
        position: absolute;
        bottom: calc(2rem + 26vw);
        right: calc(2rem + 15vw);
        z-index: 10;
        font-size: 53px;
        transform-origin: top;
        line-height: 56px;
        color: #748173;
        font-weight: 400;
      }
      .break-line{
        @include  fontTemplate(5rem, 5.5rem, $green, $regular, $play, left);
        position: absolute;
        top: -45vh;
        right: -25vw;
            strong{
                color: #fff;
                font-family: $better;
                font-weight: 100;
                line-height: 9.5rem;
                font-size: 7.5rem;
           }
           @media (max-width: 1599px) {
            font-size: 4rem;
            line-height: 3.5rem;
            strong{
                line-height: 6.5rem;
                font-size: 5.5rem;
            }
        }
      }
      .cta-position{
        right: -22vw;
        position: relative;
        top: -16vh;
        @media (max-width: 1599){
            right: 25vw;
        }
      }
      .line-father{
        overflow: hidden;
      }
      .slider__text-line {
        overflow: hidden;
        strong{
            @include  fontTemplate(7rem, 1.2, #fff, 100, $better, left);
        }
      }
      .slider__inner {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .container-text{
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        bottom: -20%;
      }
      .slider__nav {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 10;
      }
      .slider-bullet {
        display: flex;
        align-items: center;
        padding: 1rem 0;
        display: none;
      }
      .slider-bullet__text {
        color: #fff;
        font-size: 0.65rem;
        margin-right: 1rem;
      }
      .slider-bullet__line {
        background-color: #fff;
        height: 1px;
        width: 1rem;
      }
      .slider canvas {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .slide {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
      }
      .slide__content {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      .slide__img {
        position: relative;
        width: 25vw;
        height: 70vh;
        padding: 0;
        margin: 0;
        min-width: 12.5rem;
        transform-origin: top;
      }
      .slide__img:first-child {
        top: -1.5rem;
      }
      .slide__img:last-child {
        bottom: -1.5rem;
      }
      .slide__img img {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      /* Add styles for the thumbnail navigation */
      .js-slider-thumbs {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 20px;
        position: absolute;
        bottom: -300px;
        left: 0;
        z-index: 99;
        gap: 20px;
        max-width: 1280px;
        overflow-y: hidden;
        overflow-x: hidden;
        @media (max-width: 1599px) {
            max-width: 950px;
        }
      }

      .js-slider-thumb {
        cursor: pointer;
        opacity: 1;
        transition: all 0.3s ease;
        p{
            @include  fontTemplate(1.6rem, 1.2, #fff, $semi-bold, $mont, left);
            text-transform: capitalize;
            transform: translate(0px, 18px);
        }
      }
      .wrapper-img{
        width: 300px;
        height: 163px;
        overflow: hidden;
        @media (max-width: 1599px) {
            width: 250px;
        }
      }
      .js-slider-thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.3s ease;
      }

      .js-slider-thumb:hover img{
        transform: scale(1.3);
      }
      .container{
        max-width: 1280px;
        margin: 0 auto;
        position: relative;
        bottom: 34%;
        @media (max-width: 1399px) {
            max-width: 1140px;
            bottom: 34%;
        }
        @media (max-width: 768px) {
            padding: 0;
        }
      }
      .know{
        position: relative;
        bottom: -60px;
      }
      .know::after, .know::before{
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 45%;
        height: 1px;
        background: #fff;
      }
      .know::before{
        right: 0;
        left: inherit;
      }
      .know h4{
        text-align: center;
        color: #fff;
      }
      .cta-banner{
        font-size: 1.6rem;
        line-height: 1;
        color: #fff;
        font-weight: 600;
        font-family: "Montserrat";
        text-align: left;
        padding: 2.5rem 5rem;
        transition: all 0.3s ease;
        border-radius: 3.4rem;
        border: 1px solid #748173;
        width: -moz-fit-content;
        width: fit-content;
        text-decoration: none;
        @media (max-width: 1599px) {
            padding: 2rem 3rem;
        }
        &:hover{
            background: #748173;
            color: #fff;
        }
      }
}
